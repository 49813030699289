<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        disable-sort
        sort-by="id_suscripcion"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-cash-sync</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              block
            ></v-text-field>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn color="program" dark class="mb-2" @click="publico"
              >Aplicar</v-btn
            >
            <v-divider class="mx-4" vertical></v-divider>
            <v-dialog v-model="dialog" persistent max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="program"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-form ref="form0">
                          <v-select
                            :items="tipos"
                            no-data-text="Sin datos disponibles"
                            :rules="[rules.required]"
                            v-model="editedItem.frecuencia_pago"
                            label="Frecuencia de Pago"
                          ></v-select>
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-form ref="form1">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.concepto"
                            label="Concepto"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-form ref="form2">
                          <v-text-field
                            :rules="[rules.required, rules.importe]"
                            v-model="editedItem.importe"
                            label="Importe"
                          ></v-text-field>
                        </v-form>
                      </v-col>

                      <v-col cols="1">
                        <v-checkbox v-model="check"></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="form5">
                          <v-select
                            :rules="[rules.required]"
                            v-model="editedItem.fecha"
                            label="Aplicar retardo a:"
                            :disabled="!check"
                            :items="retardos"
                            no-data-text="Sin datos disponibles"
                            item-value="id"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.nombre }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.nombre }}
                            </template>
                          </v-select>
                        </v-form>
                      </v-col>
                      <v-col cols="3">
                        <v-form ref="form3">
                          <v-text-field
                            :rules="[rules.required, rules.int]"
                            v-model="editedItem.tiempo"
                            :disabled="!check"
                            placeholder="0"
                            label="Días de Retardo"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="form4">
                          <v-text-field
                            :rules="[rules.required, rules.importe]"
                            v-model="editedItem.retardo"
                            :disabled="!check"
                            placeholder="0.00"
                            label="Importe de Retardo"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="close"> Cancelar </v-btn>
                  <v-btn color="program" text @click="save"> Guardar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-h6">
                  <h5>¿Seguro que deseas eliminar este elemento?</h5>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="program" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.pass`]="{ item }">
          {{ "●".repeat(item.pass.length) }}
        </template>
        <template v-slot:[`item.id_suscripcion`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          ${{ item.importe }}
        </template>

        <template v-slot:[`item.fecha`]="{ item }">
          <template v-if="item.fecha == 'inicial'"> Fecha Inicial </template>
          <template v-else-if="item.fecha == 'final'">Fecha Final</template>
          <template v-else></template>
        </template>
        <template v-slot:[`item.tiempo`]="{ item }">
          <template v-if="item.tiempo != '0'">
            {{ item.tiempo }}
          </template>
          <template v-else>Sin Retardo</template>
        </template>
        <template v-slot:[`item.retardo`]="{ item }">
          <template v-if="item.retardo != '0.00'">
            ${{ item.retardo }}
          </template>
          <template v-else> </template>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!--<v-icon title="Editar" @click="editItem(item)"> mdi-pencil </v-icon>-->
          <v-icon title="Eliminar" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogcargo" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Aplicar Suscripción</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col dense cols="6">
                  <v-form ref="forma">
                    <v-select
                      label="Concepto"
                      :items="items"
                      v-model="id_suscripcion"
                      @change="changecargo"
                      :rules="[rules.required]"
                      item-value="id_suscripcion"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.concepto }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.concepto }}
                      </template>
                    </v-select>
                  </v-form>
                </v-col>
                <v-col dense cols="3">
                  <v-text-field
                    label="Frecuencia"
                    v-model="objeto.frecuencia_pago"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col dense cols="3">
                  <v-text-field
                    label="Importe"
                    prefix="$"
                    v-model="objeto.importe"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col dense cols="6">
                  <v-menu
                    v-model="menuDateBuscaB"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjsB"
                        label="Fecha Inicial"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        locale="es"
                        :disabled="
                          id_suscripcion == '' || id_suscripcion == null
                        "
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :rules="[rules.required]"
                      locale="es"
                      show-current="true"
                      v-model="fecha_inicial"
                      @change="fecha1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col dense cols="6">
                  <v-menu
                    v-model="menuDateBuscaE"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjsE"
                        label="Fecha Final"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          id_suscripcion == '' || id_suscripcion == null
                        "
                        locale="es"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :rules="[rules.required]"
                      locale="es"
                      show-current="true"
                      v-model="fecha_final"
                      @change="fecha2"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col dense cols="6">
                  <v-checkbox
                    v-model="check1"
                    @click="change1"
                    label="A un alumno"
                  ></v-checkbox>
                </v-col>
                <v-col dense cols="6">
                  <v-checkbox
                    v-model="check2"
                    @click="change2"
                    label="A un grupo de alumnos por suscripción"
                  ></v-checkbox>
                </v-col>
                <v-col cols="1" class="help1">
                  <div class="help2"></div>
                  <template v-if="check1">
                    <v-icon>mdi-account</v-icon>
                  </template>
                  <template v-else-if="check2">
                    <v-icon>mdi-account-group</v-icon>
                  </template>
                  <template v-else>
                    <v-icon>mdi-account-question</v-icon>
                  </template>
                  <v-spacer></v-spacer>
                </v-col>
                <template v-if="check1">
                  <v-col dense cols="11">
                    <v-form ref="formmat">
                      <v-select
                        label="Alumno"
                        :items="itemscli"
                        :rules="[rules.required]"
                        :disabled="!check1"
                        v-model="matriculafin"
                        item-value="id_cliente"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.grado }} ({{ data.item.nombre }}
                          {{ data.item.apellido_paterno }}
                          {{ data.item.apellido_materno }})
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.grado }} ({{ data.item.nombre }}
                          {{ data.item.apellido_paterno }}
                          {{ data.item.apellido_materno }})
                        </template>
                      </v-select>
                    </v-form>
                  </v-col>
                </template>
                <template v-if="check2">
                  <v-col dense cols="11">
                    <v-form ref="formsus">
                      <v-select
                        label="Suscripción"
                        :items="itemsus"
                        :rules="[rules.required]"
                        :disabled="!check2"
                        v-model="suscripcionfin"
                        item-value="id_suscripcion"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.concepto }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.concepto }}
                        </template>
                      </v-select>
                    </v-form>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="funcheck"> Cancelar </v-btn>
            <v-btn color="program" text @click="fundialognex">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogA" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Suscripcion aplicada con exito.</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogA = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialognex" persistent max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <template v-if="check1">
              <h5>
                ¿Desea aplicar la suscripción al alumno? Se crearan nuevos pagos
                ademas de los que ya existentes en el perfil del alumno.
              </h5>
            </template>
            <template v-else>
              <h5>
                ¿Desea aplicar la suscripción al grupo de alumnos? Se crearan
                nuevos pagos ademas de los que ya existentes en los perfiles del
                alumnos.
              </h5>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn color="program" text @click="dialognex = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="aceptarcar"> Aceptar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col></v-row
  >
</template>

<style>
.help1 {
  height: 78px;
}

.help2 {
  height: 23px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
      importe: (value) => {
        const pattern =
          /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
        return pattern.test(value) || "Importe inválido, ejemplo: 0.01";
      },
      int: (value) => {
        const pattern = /^([1-9][0-9]*)$/;
        return pattern.test(value) || "Numero inválido";
      },
    },
    check: false,
    menuDateBuscaB: false,
    menuDateBuscaE: false,
    fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    disabled: true,
    Message: "Internal Error",
    dialogcargo: false,
    dialognex: false,
    dialogA: false,
    dialog: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    id_suscripcion: "",

    matriculafin: "",
    suscripcionfin: "",

    objeto: {
      id_suscripcion: "",
      concepto: "",
      frecuencia_pago: "",
      importe: "",
    },
    check1: false,
    check2: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_suscripcion",
      },
      { text: "Concepto", value: "concepto", align: "center" },
      { text: "Importe", value: "importe", align: "center" },
      { text: "Días de Retardo", value: "tiempo", align: "center" },
      { text: "Aplicado a", value: "fecha", align: "center" },
      { text: "Importe de Retardo", value: "retardo", align: "center" },
      { text: "Frecuencia de Pago", value: "frecuencia_pago", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    itemsus: [],
    itemscli: [],
    retardos: [
      {
        id: "inicial",
        nombre: "Fecha Inicial",
      },
      {
        id: "final",
        nombre: "Fecha Final",
      },
    ],
    tipos: [
      "Semanal",
      "Mensual",
      "Bimestral",
      "Trimestral",
      "Semestral",
      "Anual",
    ],
    editedIndex: -1,
    id_objeto: "",
    editedItem: {
      id_suscripcion: "",
      concepto: "",
      importe: "",
      retardo: "",
      tiempo: "",
      frecuencia_pago: "",
      empresa: "",
      fecha: "",
    },
    defaultItem: {
      id_suscripcion: "",
      concepto: "",
      importe: "",
      retardo: "",
      tiempo: "",
      frecuencia_pago: "",
      empresa: "",
      fecha: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.fecha_inicial).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha_inicial ? localLocale : "";
    },
    computedDateFormattedMomentjsE() {
      var localLocale = moment(this.fecha_final).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha_final ? localLocale : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    check(val) {
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
    },
  },

  methods: {
    initialize() {
      this.items = [];
      this.cargartodo();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_suscripcion;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_suscripcion;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //this.items.splice(this.editedIndex, 1);
      this.eliminar();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        this.$refs.form0.reset();
        this.$refs.form1.reset();
        this.$refs.form2.reset();
        this.$refs.form3.reset();
        this.$refs.form4.reset();
        this.$refs.form5.reset();
        this.check = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        if (this.$refs.form1) {
          this.$refs.form0.reset();
          this.$refs.form1.reset();
          this.$refs.form2.reset();
          this.$refs.form3.reset();
          this.$refs.form4.reset();
          this.$refs.form5.reset();
        }
      });
    },

    save() {
      var x = this.check;
      if (x) {
        var z = this.$refs.form0.validate();
        var a = this.$refs.form1.validate();
        var b = this.$refs.form2.validate();
        var c = this.$refs.form3.validate();
        var d = this.$refs.form4.validate();
        var e = this.$refs.form5.validate();
        if (a && b && c && d && e && z) {
          if (this.editedIndex > -1) {
            //Object.assign(this.items[this.editedIndex], this.editedItem);
            this.editar();
          } else {
            //this.items.push(this.editedItem);
            this.agregar();
          }
          this.close();
        }
      } else {
        var z = this.$refs.form0.validate();
        var a = this.$refs.form1.validate();
        var b = this.$refs.form2.validate();
        if (a && b && z) {
          if (this.editedIndex > -1) {
            //Object.assign(this.items[this.editedIndex], this.editedItem);
            this.editar();
          } else {
            //this.items.push(this.editedItem);
            this.agregar();
          }
          this.close();
        }
      }
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaSuscripciones", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {};
      if (this.check) {
        Params = {
          concepto: vue.editedItem.concepto,
          importe: vue.editedItem.importe,
          retardo: vue.editedItem.retardo,
          tiempo: vue.editedItem.tiempo,
          frecuencia_pago: vue.editedItem.frecuencia_pago,
          fecha: vue.editedItem.fecha,
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
      } else {
        Params = {
          concepto: vue.editedItem.concepto,
          importe: vue.editedItem.importe,
          retardo: "0.00",
          tiempo: "0",
          frecuencia_pago: vue.editedItem.frecuencia_pago,
          fecha: "",
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
      }
      axios
        .post("api/SistemaCobros/InsertaSuscripciones", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
        });
    },

    // editar() {
    //   var vue = this;
    //   vue.dialogIni = true;
    //   var Params = {};
    //   if (this.check) {
    //     Params = {
    //       id_suscripcion: vue.editedItem.id_suscripcion,
    //       concepto: vue.editedItem.concepto,
    //       importe: vue.editedItem.importe,
    //       retardo: vue.editedItem.retardo,
    //       tiempo: vue.editedItem.tiempo,
    //       empresa: vue.$store.state.empresa,
    //       u: vue.$store.state.usuario,
    //     };
    //   } else {
    //     Params = {
    //       id_suscripcion: vue.editedItem.id_suscripcion,
    //       concepto: vue.editedItem.concepto,
    //       importe: vue.editedItem.importe,
    //       retardo: "0.00",
    //       tiempo: "0",
    //       empresa: vue.$store.state.empresa,
    //       u: vue.$store.state.usuario,
    //     };
    //   }
    //   axios
    //     .post("api/SistemaCobros/ActualizaSuscripciones", Params)
    //     .then(function (response) {
    //       vue.items = response.data;
    //       vue.id_objeto = "";
    //       vue.dialogIni = false;
    //     })
    //     .catch(function (error) {
    //       vue.dialogIni = false;
    //       if (error.response) {
    //         if (error.response.status == 400) {
    //           vue.Message = error.response.data;
    //           vue.dialog404 = true;
    //         } else {
    //           vue.dialog500 = true;
    //         }
    //       } else {
    //         vue.dialog500 = true;
    //       }
    //       console.log(error);
    //       vue.id_objeto = "";
    //     });
    // },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_suscripcion: vue.id_objeto,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/DesactivaSuscripciones", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
        });
    },
    funcheck() {
      this.matriculafin = "";
      this.suscripcionfin = "";
      this.$refs.forma.reset();
      if (this.check1) {
        this.$refs.formmat.reset();
      }
      if (this.check2) {
        this.$refs.formsus.reset();
      }
      this.check1 = false;
      this.check2 = false;
      this.disabled = true;
      this.dialogcargo = false;
    },
    fechas() {
      var a = this.fecha_inicial;
      let now = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(now.setDate(now.getDate() + 1));
      this.fecha_final = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      this.fechasfinal();
    },
    fechasfinal() {
      var a = this.fecha_inicial;
      var b = this.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let dat2 = dati;
      if (dati < datf) {
        if (this.objeto.frecuencia_pago == "Anual") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 12));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else if (this.objeto.frecuencia_pago == "Semestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 6));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else if (this.objeto.frecuencia_pago == "Trimestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 3));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else if (this.objeto.frecuencia_pago == "Bimestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 2));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else if (this.objeto.frecuencia_pago == "Mensual") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 1));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else if (this.objeto.frecuencia_pago == "Semanal") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setDate(dat2.getDate() + 7));
          }
          this.fecha_final = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        } else {
          console.log("error de fechas");
        }
      } else {
        this.fechas();
      }
    },
    fecha1() {
      this.menuDateBuscaB = false;
      this.fechas();
    },
    fecha2() {
      this.menuDateBuscaE = false;
      this.fechasfinal();
    },

    publico() {
      this.fecha1();
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ListasCargos", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.itemsus = response.data.suscripciones;
          vue.itemscli = response.data.clientes;
          vue.dialogcargo = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    changecargo() {
      this.objeto = this.items.find(
        (x) => x.id_suscripcion === this.id_suscripcion
      );
      this.fechas();
    },
    aceptarcar() {
      if (this.check1) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          id_suscripcion: vue.id_suscripcion,
          id_cliente: vue.matriculafin,
          fecha_inicial: vue.fecha_inicial,
          fecha_final: vue.fecha_final,
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
        axios
          .post("api/SistemaCobros/AplicarSuscripcionMat", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.dialognex = false;
            vue.dialogcargo = false;
            vue.dialogA = true;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            vue.dialogcargo = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      } else if (this.check2) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          id_suscripcion_app: vue.id_suscripcion,
          id_suscripcion_grupo: vue.suscripcionfin,
          fecha_inicial: vue.fecha_inicial,
          fecha_final: vue.fecha_final,
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
        axios
          .post("api/SistemaCobros/AplicarSuscripcionSus", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.dialognex = false;
            vue.dialogcargo = false;
            vue.dialogA = true;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            vue.dialogcargo = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },
    change1() {
      if (this.check1) {
        this.check2 = false;
      }
    },
    change2() {
      if (this.check2) {
        this.check1 = false;
      }
    },

    fundialognex() {
      var a = this.$refs.forma.validate();
      if (a) {
        if (this.check1) {
          var b = this.$refs.formmat.validate();
          if (b) {
            this.dialognex = true;
          }
        } else if (this.check2) {
          var b = this.$refs.formsus.validate();
          if (b) {
            this.dialognex = true;
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.fecha_inicial).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.fecha_final).format("DD MMM YYYY");
      moment.locale("es");
    }
  },
};
</script>