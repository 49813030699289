<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        disable-sort
        sort-by="id_administrador"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-account-tie-hat</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              block
            ></v-text-field>
            <v-divider class="mx-4" vertical></v-divider>
            <v-dialog v-model="dialog" persistent max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="program"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="formopt">
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.nombre"
                            label="Nombre"
                            minlength="20"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.apellido_paterno"
                            label="Apellido Paterno"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.apellido_materno"
                            label="Apellido Materno"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.puesto"
                            label="Puesto"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :rules="specialrule"
                            v-model="editedItem.user"
                            label="Usuario"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.pass"
                            type="password"
                            label="Contraseña"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="close"> Cancelar </v-btn>
                  <v-btn color="program" text @click="save"> Guardar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-h6">
                  <h5>¿Seguro que deseas eliminar este elemento?</h5>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="program" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogP" persistent max-width="750px">
              <v-card>
                <v-card-title>
                  <span class="text-h5"> Permisos </span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row small dense>
                      <v-col cols="6">
                        <v-row>
                          <v-spacer></v-spacer>
                          <h5>Usuarios</h5>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[0]">
                            <template v-slot:label>
                              <v-icon title="Ver Tabla de Usuarios">
                                mdi-eye
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[1]">
                            <template v-slot:label>
                              <v-icon title="Añadir Usuarios">
                                mdi-plus
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[2]">
                            <template v-slot:label>
                              <v-icon title="Editar Usuarios">
                                mdi-pencil
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[3]">
                            <template v-slot:label>
                              <v-icon title="Eliminar Usuarios">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-row>
                          <v-spacer></v-spacer>
                          <h5>Alumnos</h5>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[4]">
                            <template v-slot:label>
                              <v-icon title="Ver Tabla de Alumnos">
                                mdi-eye
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[5]">
                            <template v-slot:label>
                              <v-icon title="Añadir Alumnos"> mdi-plus </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[6]">
                            <template v-slot:label>
                              <v-icon title="Editar Alumnos">
                                mdi-pencil
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[7]">
                            <template v-slot:label>
                              <v-icon title="Eliminar Alumnos">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-col>
                      <v-col cols="9">
                        <v-row>
                          <v-col cols="6">
                            <v-row>
                              <v-spacer></v-spacer>
                              <h5>Suscripciones</h5>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[8]">
                                <template v-slot:label>
                                  <v-icon title="Ver Tabla de Suscripciones">
                                    mdi-eye
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[9]">
                                <template v-slot:label>
                                  <v-icon title="Añadir Suscripciones">
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                              <!--<v-checkbox v-model="check[10]">
                            <template v-slot:label>
                              <v-icon title="Editar Suscripciones">
                                mdi-pencil
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>-->
                              <v-checkbox v-model="check[11]">
                                <template v-slot:label>
                                  <v-icon title="Eliminar Suscripciones">
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                            </v-row>
                          </v-col>
                          <v-col cols="6">
                            <v-row>
                              <v-spacer></v-spacer>
                              <h5>Cargos</h5>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[12]">
                                <template v-slot:label>
                                  <v-icon title="Ver Tabla de Cargos">
                                    mdi-eye
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[13]">
                                <template v-slot:label>
                                  <v-icon title="Añadir Cargos">
                                    mdi-plus
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                              <!--<v-checkbox v-model="check[14]">
                            <template v-slot:label>
                              <v-icon title="Editar Cargos">
                                mdi-pencil
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>-->
                              <v-checkbox v-model="check[15]">
                                <template v-slot:label>
                                  <v-icon title="Eliminar Cargos">
                                    mdi-delete
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="3">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-spacer></v-spacer>
                              <h5>Empresa</h5>
                              <v-spacer></v-spacer>
                            </v-row>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[22]">
                                <template v-slot:label>
                                  <v-icon title="Ver Informacion Empresarial">
                                    mdi-eye
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                              <v-checkbox v-model="check[23]">
                                <template v-slot:label>
                                  <v-icon
                                    title="Editar Informacion Empresarial"
                                  >
                                    mdi-pencil
                                  </v-icon>
                                </template>
                              </v-checkbox>
                              <v-spacer></v-spacer>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="10">
                        <v-row>
                          <v-spacer></v-spacer>
                          <h5>Pagos</h5>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[16]">
                            <template v-slot:label>
                              <v-icon title="Ver Tabla de Pagos">
                                mdi-eye
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[17]">
                            <template v-slot:label>
                              <v-icon title="Añadir Pagos"> mdi-plus </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[18]">
                            <template v-slot:label>
                              <v-icon title="Editar Pagos"> mdi-pencil </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[19]">
                            <template v-slot:label>
                              <v-icon title="Eliminar Pagos">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[20]">
                            <template v-slot:label>
                              <v-icon title="Registrar Pagos">
                                mdi-cash
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-col>
                      <v-col cols="2">
                        <v-row>
                          <v-spacer></v-spacer>
                          <h5>Historial</h5>
                          <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-checkbox v-model="check[21]">
                            <template v-slot:label>
                              <v-icon title="Ver Historial de Actividades">
                                mdi-eye
                              </v-icon>
                            </template>
                          </v-checkbox>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="pcan"> Cancelar </v-btn>
                  <v-btn color="program" text @click="pace"> Guardar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.nombre`]="{ item }">
          {{
            item.nombre +
            " " +
            item.apellido_paterno +
            " " +
            item.apellido_materno
          }}
        </template>
        <template v-slot:[`item.id_administrador`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Editar" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon title="Eliminar" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <template v-if="$store.state.token == 'Admin'">
            <v-icon title="Permisos" @click="permission(item)">
              mdi-account-key
            </v-icon>
          </template>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog> </v-col
  ></v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
    },
    Message: "Internal Error",
    dialog: false,
    dialogP: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_administrador",
      },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Puesto", value: "puesto", align: "center" },
      { text: "Usuario", value: "user", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    check: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    idadmin: "",
    editedIndex: -1,
    id_objeto: "",
    editedItem: {
      id_administrador: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      puesto: "",
      user: "",
      pass: "",
    },
    defaultItem: {
      id_administrador: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      puesto: "",
      user: "",
      pass: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
    specialrule() {
      const rules = [];
      const ruler = (v) => !!v || "Requerido";
      rules.push(ruler);
      const word = this.$store.state.empresa.substr(7);
      var w = "";
      for (var i = 0; i < word.length; i++) {
        w = w + "[" + word[i] + "]";
      }
      const p = "^(" + w + "|" + w + "[_]\\w+)$";
      const pattern = new RegExp(p);
      const rule = (value) =>
        pattern.test(value) || "Debe tener forma: " + word + "_(usuario)";
      rules.push(rule);
      return rules;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    initialize() {
      this.items = [];
      this.cargartodo();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_administrador;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_administrador;
      this.dialogDelete = true;
    },

    permission(item) {
      var vue = this;
      vue.dialogIni = true;
      vue.idadmin = item.id_administrador;
      var Params = {
        id: vue.idadmin,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaPermisos", Params)
        .then(function (response) {
          var p = response.data;
          for (var i = 0; i < p.length; i++) {
            if (p[i] == "0") {
              vue.check[i] = false;
            } else if (p[i] == "1") {
              vue.check[i] = true;
            } else {
              console.log("Error de check");
            }
          }
          vue.dialogIni = false;
          vue.dialogP = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.idadmin = "";
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    deleteItemConfirm() {
      //this.items.splice(this.editedIndex, 1);
      this.eliminar();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        this.$refs.formopt.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        if (this.$refs.formopt) {
          this.$refs.formopt.reset();
        }
      });
    },

    save() {
      if (this.$refs.formopt.validate()) {
        if (this.editedIndex > -1) {
          //Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editar();
        } else {
          //this.items.push(this.editedItem);
          this.agregar();
        }
      }
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaEjecutivos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        nombre: vue.editedItem.nombre,
        apellido_paterno: vue.editedItem.apellido_paterno,
        apellido_materno: vue.editedItem.apellido_materno,
        puesto: vue.editedItem.puesto,
        user: vue.editedItem.user,
        pass: vue.editedItem.pass,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/InsertaEjecutivos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.close();
        });
    },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_administrador: vue.id_objeto,
        nombre: vue.editedItem.nombre,
        apellido_paterno: vue.editedItem.apellido_paterno,
        apellido_materno: vue.editedItem.apellido_materno,
        puesto: vue.editedItem.puesto,
        user: vue.editedItem.user,
        pass: vue.editedItem.pass,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ActualizaEjecutivos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.close();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_administrador: vue.id_objeto,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/DesactivaEjecutivos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;
          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.closeDelete();
        });
    },
    pcan() {
      for (var i = 0; i < this.check.length; i++) {
        this.check[i] = false;
      }
      this.dialogP = false;
      this.idadmin = "";
    },
    pace() {
      var vue = this;
      vue.dialogIni = true;
      var pp = "";
      for (var i = 0; i < vue.check.length; i++) {
        if (vue.check[i]) {
          pp = pp + "1";
        } else {
          pp = pp + "0";
        }
      }
      var Params = {
        permisos: pp,
        id: vue.idadmin,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      vue.idadmin = "";
      axios
        .post("api/SistemaCobros/EditPermisos", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.dialogP = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
};
</script>