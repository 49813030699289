import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        token: null,
        usuario: null,
        pass: null,
        empresa: null,
        nombre: null,
        cliusuario: null,
        clipass: null,
        clicode: null,
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUsuario(state, usuario) {
            state.usuario = usuario
        },
        setPass(state, pass) {
            state.pass = pass
        },
        setEmpresa(state, empresa) {
            state.empresa = empresa
        },
        setNombre(state, nombre) {
            state.nombre = nombre
        },
        setCliUsuario(state, cliusuario) {
            state.cliusuario = cliusuario
        },
        setCliPass(state, clipass) {
            state.clipass = clipass
        },
        setCliCode(state, clicode) {
            state.clicode = clicode
        },
    },
    actions: {
        guardartoken({ commit }, token) {
            commit("setToken", token)
            localStorage.setItem("token", token)
        },
        guardarusuario({ commit }, usuario) {
            commit("setUsuario", usuario)
            localStorage.setItem("usuario", usuario)
        },
        guardarpass({ commit }, pass) {
            commit("setPass", pass)
            localStorage.setItem("pass", pass)
        },
        guardarempresa({ commit }, empresa) {
            commit("setEmpresa", empresa)
            localStorage.setItem("empresa", empresa)
        },
        guardarNOMBRE({ commit }, nombre) {
            commit("setNombre", nombre)
            localStorage.setItem("nombre", nombre)
        },
        guardarcliusuario({ commit }, cliusuario) {
            commit("setCliUsuario", cliusuario)
            localStorage.setItem("cliusuario", cliusuario)
        },
        guardarclipass({ commit }, clipass) {
            commit("setCliPass", clipass)
            localStorage.setItem("clipass", clipass)
        },
        guardarclicode({ commit }, clicode) {
            commit("setCliCode", clicode)
            localStorage.setItem("clicode", clicode)
        },
        autologin({ commit }) {
            let token = localStorage.getItem("token")
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            if (token != "" && token != null) {
                var Params = {
                    user: u,
                    pass: p
                };
                axios
                    .post("api/SistemaCobros/LoginUsuariosIni", Params)
                    .then(function(response) {
                        commit("setToken", response.data.token)
                        localStorage.setItem("token", response.data.token)
                        commit("setUsuario", response.data.user)
                        localStorage.setItem("usuario", response.data.user)
                        commit("setPass", response.data.pass)
                        localStorage.setItem("pass", response.data.pass)
                        commit("setEmpresa", response.data.empresa)
                        localStorage.setItem("empresa", response.data.empresa)
                        commit("setNombre", response.data.nombre)
                        localStorage.setItem("nombre", response.data.nombre)
                        document.getElementById("todo").style.display = "block";
                    })
                    .catch(function(error) {
                        console.log(error);
                        commit("setToken", null)
                        commit("setUsuario", null)
                        commit("setPass", null)
                        commit("setEmpresa", null)
                        commit("setNombre", null)
                        localStorage.removeItem("token")
                        localStorage.removeItem("usuario")
                        localStorage.removeItem("pass")
                        localStorage.removeItem("empresa")
                        localStorage.removeItem("nombre")
                        router.push({ name: "Login" });
                        document.getElementById("todo").style.display = "none";
                    });
            } else {
                commit("setToken", null)
                commit("setUsuario", null)
                commit("setPass", null)
                commit("setEmpresa", null)
                commit("setNombre", null)
                localStorage.removeItem("token")
                localStorage.removeItem("usuario")
                localStorage.removeItem("pass")
                localStorage.removeItem("empresa")
                localStorage.removeItem("nombre")
                router.push({ name: "Login" });
                document.getElementById("todo").style.display = "none";
            }
        },
        homelogin({ commit }) {
            let token = localStorage.getItem("token")
            let u = localStorage.getItem("usuario")
            let p = localStorage.getItem("pass")
            if (token != "" && token != null) {
                var Params = {
                    user: u,
                    pass: p
                };
                axios
                    .post("api/SistemaCobros/LoginUsuariosIni", Params)
                    .then(function(response) {
                        commit("setToken", response.data.token)
                        localStorage.setItem("token", response.data.token)
                        commit("setUsuario", response.data.user)
                        localStorage.setItem("usuario", response.data.user)
                        commit("setPass", response.data.pass)
                        localStorage.setItem("pass", response.data.pass)
                        commit("setEmpresa", response.data.empresa)
                        localStorage.setItem("empresa", response.data.empresa)
                        commit("setNombre", response.data.nombre)
                        localStorage.setItem("nombre", response.data.nombre)
                        router.push({ name: "Menu" });
                        document.getElementById("todo").style.display = "block";
                    })
                    .catch(function(error) {
                        console.log(error);
                        commit("setToken", null)
                        commit("setUsuario", null)
                        commit("setPass", null)
                        commit("setEmpresa", null)
                        commit("setNombre", null)
                        localStorage.removeItem("token")
                        localStorage.removeItem("usuario")
                        localStorage.removeItem("pass")
                        localStorage.removeItem("empresa")
                        localStorage.removeItem("nombre")
                        document.getElementById("todo").style.display = "none";
                    });
            } else {
                commit("setToken", null)
                commit("setUsuario", null)
                commit("setPass", null)
                commit("setEmpresa", null)
                commit("setNombre", null)
                localStorage.removeItem("token")
                localStorage.removeItem("usuario")
                localStorage.removeItem("pass")
                localStorage.removeItem("empresa")
                localStorage.removeItem("nombre")
                document.getElementById("todo").style.display = "none";
            }
        },
        clienteautologin({ commit }) {
            let cliusu = localStorage.getItem("cliusuario")
            let clipas = localStorage.getItem("clipass")
            let clicod = localStorage.getItem("clicode")
            if (clicod != "" && clicod != null) {
                var Params = {
                    user: cliusu,
                    pass: clipas,
                    codigo: clicod,
                };
                axios
                    .post("api/SistemaCobros/LoginClientes", Params)
                    .then(function(response) {
                        commit("setCliUsuario", response.data.user)
                        localStorage.setItem("cliusuario", response.data.user)
                        commit("setCliPass", response.data.pass)
                        localStorage.setItem("clipass", response.data.pass)
                        commit("setCliCode", response.data.empresa)
                        localStorage.setItem("clicode", response.data.empresa)
                    })
                    .catch(function(error) {
                        console.log(error)
                        commit("setCliUsuario", null)
                        commit("setCliPass", null)
                        commit("setCliCode", null)
                        localStorage.removeItem("cliusuario")
                        localStorage.removeItem("clipass")
                        localStorage.removeItem("clicode")
                        router.push({ name: "CliLogin" });
                    });
            } else {
                commit("setCliUsuario", null)
                commit("setCliPass", null)
                commit("setCliCode", null)
                localStorage.removeItem("cliusuario")
                localStorage.removeItem("clipass")
                localStorage.removeItem("clicode")
                router.push({ name: "CliLogin" });
            }
        },
        clientehomelogin({ commit }) {
            let cliusu = localStorage.getItem("cliusuario")
            let clipas = localStorage.getItem("clipass")
            let clicod = localStorage.getItem("clicode")
            if (clicod != "" && clicod != null) {
                var Params = {
                    user: cliusu,
                    pass: clipas,
                    codigo: clicod,
                };
                axios
                    .post("api/SistemaCobros/LoginClientes", Params)
                    .then(function(response) {
                        commit("setCliUsuario", response.data.user)
                        localStorage.setItem("cliusuario", response.data.user)
                        commit("setCliPass", response.data.pass)
                        localStorage.setItem("clipass", response.data.pass)
                        commit("setCliCode", response.data.empresa)
                        localStorage.setItem("clicode", response.data.empresa)
                        router.push({ name: "CliPagar" });
                    })
                    .catch(function(error) {
                        console.log(error)
                        commit("setCliUsuario", null)
                        commit("setCliPass", null)
                        commit("setCliCode", null)
                        localStorage.removeItem("cliusuario")
                        localStorage.removeItem("clipass")
                        localStorage.removeItem("clicode")
                    });
            } else {
                commit("setCliUsuario", null)
                commit("setCliPass", null)
                commit("setCliCode", null)
                localStorage.removeItem("cliusuario")
                localStorage.removeItem("clipass")
                localStorage.removeItem("clicode")
            }
        },
        appsalir({ commit }) {
            commit("setToken", null)
            commit("setUsuario", null)
            commit("setPass", null)
            commit("setEmpresa", null)
            localStorage.removeItem("token")
            localStorage.removeItem("usuario")
            localStorage.removeItem("pass")
            localStorage.removeItem("empresa")
            router.push({ name: "Login" });
            document.getElementById("todo").style.display = "none";
        }
    },
})