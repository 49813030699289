<template>
  <v-row>
    <v-col>
      <v-row>
        <v-btn id="g1" large dark color="program" text @click="salir">
          <v-icon dark small color="program">mdi-arrow-left</v-icon>
          <pre>&nbsp;</pre>
          Atras
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn large dark color="program" text @click="contago">
          <v-icon dark small color="program">mdi-phone</v-icon>
          <pre>&nbsp;</pre>
          Contactanos
        </v-btn>
      </v-row>
      <v-card class="mx-auto my-12" max-width="400">
        <br />
        <v-row>
          <v-spacer></v-spacer>
          <v-img
            src="@/assets/contago.png"
            transition="scale-transition"
            max-height="200"
            max-width="300"
          />
          <v-spacer></v-spacer>
        </v-row>
        <v-form ref="form1">
          <v-row>
            <v-col cols="2"> </v-col>
            <v-col cols="8">
              <v-select
                v-model="datos.codigo"
                label="Empresa"
                outlined
                :items="itemsnom"
                small
                :rules="[rules.required]"
                @change="act"
                dense
              ></v-select>
              <v-text-field
                v-model="datos.user"
                label="Matricula"
                outlined
                small
                :rules="[rules.required]"
                dense
              ></v-text-field>
              <v-text-field
                v-model="datos.pass"
                type="password"
                label="Contraseña"
                outlined
                small
                :rules="[rules.required]"
                dense
              ></v-text-field>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn id="g1" large dark color="program" text @click="login()">
                  Iniciar Sesión
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    Message: "Internal Error",
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    itemsnom: [],
    itemsemp: [],
    vid: "pruebacobros",
    datos: {
      user: "",
      pass: "",
      codigo: "Empresa de Prueba",
    },
    rules: {
      required: (value) => !!value || "Requerido",
    },
  }),
  methods: {
    login() {
      var vue = this;
      if (this.$refs.form1.validate()) {
        vue.dialogIni = true;
        var Params = {
          user: vue.datos.user,
          pass: vue.datos.pass,
          codigo: vue.vid,
        };
        axios
          .post("api/SistemaCobros/LoginClientes", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.$store.dispatch("guardarcliusuario", Params.user);
            vue.$store.dispatch("guardarclipass", Params.pass);
            vue.$store.dispatch("guardarclicode", Params.codigo);
            vue.$router.push({ name: "CliPagar" });
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
            vue.$store.dispatch("guardarcliusuario", "");
            vue.$store.dispatch("guardarclipass", "");
            vue.$store.dispatch("guardarclicode", "");
            vue.$refs.form1.reset();
          });
      }
    },
    cargar() {
      var vue = this;
      vue.dialogIni = true;
      vue.itemsnom = [];
      vue.itemsemp = [];
      axios
        .post("api/SistemaCobros/Empresas")
        .then(function (response) {
          vue.dialogIni = false;
          for (var i = 0; i < response.data.length; i++) {
            vue.itemsnom.push(response.data[i].nombre);
            vue.itemsemp.push(response.data[i].codigo);
          }
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    salir() {
      this.$store.dispatch("guardarcliusuario", "");
      this.$store.dispatch("guardarclipass", "");
      this.$store.dispatch("guardarclicode", "");
      this.$router.push({ name: "Home" });
    },
    act() {
      var aux = this.itemsnom.indexOf(this.datos.codigo);
      this.vid = this.itemsemp[aux];
    },
    contago() {
      window.open("https://contago.com.mx/contago-cobros");
    },
  },
  mounted() {
    this.$store.dispatch("clientehomelogin");
    this.cargar();
  },
};
</script>