<template>
  <v-app>
    <v-card class="mx-auto overflow-hidden" tile height="100%" width="100%">
      <v-app-bar dense color="program" dark>
        <v-col id="todo" class="to">
          <v-row>
            <v-app-bar-nav-icon
              dense
              title="Menú"
              @click.stop="drawer = !drawer"
            >
              <v-img
                src="@/assets/contago_mini.png"
                transition="scale-transition"
                max-height="50"
                max-width="50"
              />
            </v-app-bar-nav-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-spacer></v-spacer>
            <div class="user">
              <v-toolbar-title> {{ $store.state.usuario }}</v-toolbar-title>
            </div>
            <v-divider class="mx-4" vertical></v-divider>
            <div class="user">
              <v-toolbar-title> {{ $store.state.nombre }}</v-toolbar-title>
            </div>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn title="Cerrar Sesión" @click="dialog = true" icon>
              <v-icon large>mdi-logout-variant</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary app clipped>
        <v-list>
          <template>
            <v-list-item @click="action" :to="{ path: '/administradores' }">
              <v-list-item-action>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Administradores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/usuarios' }">
              <v-list-item-action>
                <v-icon>mdi-account-tie-hat</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/clientes' }">
              <v-list-item-action>
                <v-icon>mdi-card-account-details</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <!-- <v-list-item-title>Clientes</v-list-item-title> CONTAGIO COBROS -->
                <v-list-item-title>Alumnos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/pagos' }">
              <v-list-item-action>
                <v-icon>mdi-cash-register</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Pagos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/suscripciones' }">
              <v-list-item-action>
                <v-icon>mdi-cash-sync</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Suscripciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/cargos' }">
              <v-list-item-action>
                <v-icon>mdi-cash-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Cargos Únicos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/actividades' }">
              <v-list-item-action>
                <v-icon>mdi-calendar-check</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Actividades</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/gastos' }">
              <v-list-item-action>
                <v-icon>mdi-scale-balance</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ingresos y Egresos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/reportes' }">
              <v-list-item-action>
                <v-icon>mdi-chart-bar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/historial' }">
              <v-list-item-action>
                <v-icon>mdi-book-open-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Historial</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="action" :to="{ path: '/empresa' }">
              <v-list-item-action>
                <v-icon>mdi-office-building</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Empresa</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container fluid>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
    </v-card>
    <v-dialog persistent v-model="dialog" max-width="350">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="text-h6">
          <h5>¿Desea cerrar la sesión?</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="program" text @click="dialog = false">
            <h4>Cancelar</h4>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="appsalir">
            <h4>Aceptar</h4>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="program" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
.user {
  display: flex;
  align-self: flex-end;
  height: 40px;
}
.to {
  display: none;
}
.timg {
  width: 10px;
}
</style>

<script>
export default {
  name: "App",
  data: () => ({
    dialog: false,
    dialog500: false,
    drawer: false,
    group: null,
  }),
  methods: {
    appsalir() {
      this.dialog = false;
      this.$store.dispatch("appsalir");
    },

    action() {
      if (this.$store.state.token == "espera") {
        this.dialog500 = true;
      }
    },

    // async remove() {
    //   console.log("1");
    //   await new Promise((resolve) => setTimeout(resolve, 3000));
    //   console.log("2");
    // },
  },

  /*
  Error AXIOS
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  */
};
</script>


