<template>
<v-col>
  <v-row>
    <v-spacer />
    <v-img
      src="@/assets/contago.png"
      transition="scale-transition"
      max-height="60vh"
      max-width="100vh"
    />
    <v-spacer />
  </v-row>
  <v-row>
    <v-spacer />
    <h1>¡Bienvenido!</h1>
    <v-spacer />
  </v-row> 
</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({

  }),
  mounted() {
    this.$store.dispatch("autologin");
  },
};
</script>