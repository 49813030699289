<!--<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-spacer></v-spacer>
        <v-img
          src="@/assets/contago.png"
          transition="scale-transition"
          max-height="500"
          max-width="500"
        />
        <v-spacer></v-spacer>
        <v-btn large dark color="program" text @click="contago">
          <v-icon dark small color="program">mdi-phone</v-icon>
          <pre>&nbsp;</pre>
          Contactanos
        </v-btn>
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <v-card rounded="xl">
            <v-container class="a1" @click="primero">
              <v-row><v-col></v-col></v-row>
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col class="object-fit: fill;">
                  <v-btn x-large dark text>Soy un alumno</v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <div>
                  <v-icon dark x-large>mdi-account</v-icon>
                </div>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <v-card rounded="xl"
            ><v-container class="a1" @click="segundo">
              <v-row><v-col></v-col></v-row>
              <v-row><v-col></v-col></v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col class="object-fit: fill;">
                  <v-btn x-large dark text>Soy un trabajador</v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <div>
                  <v-icon dark x-large>mdi-account-tie</v-icon>
                </div>
                <v-spacer></v-spacer>
              </v-row> </v-container
          ></v-card>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-col>
  </v-row>
</template>

<style>
.a1 {
  object-fit: fill;
  min-height: 275px;
  background-color: rgb(0, 129, 138);
}
</style>

<script>
export default {
  methods: {
    primero() {
      this.$router.push({ name: "CliLogin" });
    },
    segundo() {
      this.$router.push({ name: "Login" });
    },
    contago() {
      window.open("https://contago.com.mx/contago-cobros");
    },
  },
};
</script>-->
<template>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
  }),
  methods: {
  },
  created() {
    // this.$router.push({ name: "Inicio" });
      location.href = "login";
  },
};
</script>