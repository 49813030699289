<template>
  <v-row dense>
    <v-col cols="12">
      <v-btn large dark color="program" text @click="salir">
        <v-icon dark color="program">mdi-arrow-left</v-icon>
        <pre>&nbsp;&nbsp;</pre>
        Salir
      </v-btn>
    </v-col>
    <v-col dense cols="12">
      <br />
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nombre"
        label="Nombre"
        rounded
        dense
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-form ref="form1">
        <v-text-field
          :rules="[rules.required]"
          v-model="matricula"
          dense
          rounded
          readonly
          label="Matrícula"
        >
        </v-text-field>
      </v-form>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nuevo.sexo"
        label="Sexo"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="fecha"
        label="Fecha de Nacimiento"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nuevo.domicilio"
        label="Domicilio"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nuevo.curp"
        label="CURP"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nuevo.correo"
        label="Correo"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="3">
      <v-text-field
        v-model="nuevo.telefono"
        label="Telefono"
        rounded
        dense
        small
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="6">
      <v-text-field
        v-model="nuevo.concepto"
        label="Suscripcion"
        rounded
        dense
        readonly
      ></v-text-field>
    </v-col>
    <v-col dense cols="2">
      <v-text-field
        v-model="nuevo.deuda_anterior"
        label="Deuda Anterior"
        rounded
        dense
        small
        readonly
        prefix="$"
      ></v-text-field>
    </v-col>
    <v-col dense cols="2">
      <v-text-field
        v-model="nuevo.deuda_actual"
        label="Deuda Actual"
        rounded
        dense
        small
        readonly
        prefix="$"
      ></v-text-field>
    </v-col>
    <v-col dense cols="2">
      <v-text-field
        v-model="nuevo.deuda_total"
        label="Deuda Total"
        rounded
        dense
        small
        readonly
        prefix="$"
      ></v-text-field>
    </v-col>
    <v-col dense cols="12">
      <v-data-table
        dense
        :headers="headers"
        :items="nuevo.registros"
        :search="search"
        disable-sort
        sort-by="id_orden"
        class="elevation-1"
        :item-class="itemRowBackground"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Pagos</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              block
            ></v-text-field>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn class="mb-2" dark color="program" @click="dialogpago = true"
              ><v-icon dark>mdi-cash</v-icon>
              <pre>&nbsp;&nbsp;</pre>
              PAGAR</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.fecha_ini`]="{ item }">
          {{
            item.fecha_inicial.substr(8, 2) +
            "/" +
            item.fecha_inicial.substr(5, 2) +
            "/" +
            item.fecha_inicial.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          {{
            item.fecha_final.substr(8, 2) +
            "/" +
            item.fecha_final.substr(5, 2) +
            "/" +
            item.fecha_final.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_vig`]="{ item }">
          <template v-if="item.fecha_vigencia.length == 10">
            {{
              item.fecha_vigencia.substr(8, 2) +
              "/" +
              item.fecha_vigencia.substr(5, 2) +
              "/" +
              item.fecha_vigencia.substr(0, 4)
            }}
          </template>
          <template v-else>
            {{ item.fecha_vigencia }}
          </template>
        </template>
        <template v-slot:[`item.id_registro`]="{ item }">
          {{ nuevo.registros.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <template
            v-if="
              item.estado == 'Pagado' ||
              item.estado == 'No Pagado' ||
              item.estado == '' ||
              item.estado == null
            "
          >
            {{ item.estado }}
          </template>
          <template v-else> ${{ item.estado }} </template>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <template v-if="item.actual == 'Deuda'">
            ${{ item.importe }}
          </template>
          <template v-if="item.actual == 'Pago'">
            -${{ item.importe }}
          </template>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogpago" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Sistema de pagos automáticos en construcción</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogpago = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style>
.style-1 {
  color: rgb(0, 0, 0);
  background-color: rgb(226, 226, 226);
}
.style-2 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 238, 183);
}
.style-3 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
.style-11 {
  color: rgb(0, 132, 0);
  background-color: rgb(226, 226, 226);
}
.style-22 {
  color: rgb(0, 132, 0);
  background-color: rgb(255, 238, 183);
}
.style-33 {
  color: rgb(0, 132, 0);
  background-color: rgb(255, 255, 255);
}
</style>


<script>
import axios from "axios";
export default {
  data: () => ({
    Message: "",
    dialogpago: false,
    dialogIni: false,
    dialog404: false,
    dialog500: false,
    matricula: "",
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
      importe: (value) => {
        const pattern =
          /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
        return pattern.test(value) || "Importe inválido, ejemplo: 0.01";
      },
    },
    fecha: "",
    nombre: "",
    nuevo: {
      id_cliente: "",
      matricula: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_inicial: "",
      fecha_final: "",
      deuda_anterior: "",
      deuda_actual: "",
      deuda_total: "",
      curp: "",
      fecha_nacimiento: "",
      sexo: "",
      domicilio: "",
      correo: "",
      telefono: "",
      pagado: "",
      concepto: "",
      registros: [],
    },
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_registro",
      },
      { text: "Fecha Inicial", value: "fecha_ini", align: "center" },
      { text: "Fecha Final", value: "fecha_fin", align: "center" },
      { text: "Fecha Vigencia", value: "fecha_vig" },
      { text: "Concepto", value: "concepto", align: "center" },
      { text: "Importe", value: "importe", align: "center" },
      { text: "Estado", value: "estado", align: "center" },
    ],
  }),

  methods: {
    itemRowBackground: function (item) {
      var a = item.fecha_inicial;
      var b = item.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      hoy.setHours(0, 0, 0, 0);
      if (item.actual == "Pago") {
        if (hoy >= datf) {
          return "style-11";
        } else {
          if (hoy < dati) {
            return "style-33";
          } else {
            return "style-22";
          }
        }
      } else {
        if (hoy >= datf) {
          return "style-1";
        } else {
          if (hoy < dati) {
            return "style-3";
          } else {
            return "style-2";
          }
        }
      }
    },
    salir() {
      this.$store.dispatch("guardarcliusuario", "");
      this.$store.dispatch("guardarclipass", "");
      this.$store.dispatch("guardarclicode", "");
      this.$router.push({ name: "CliLogin" });
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    // cargartodo() {
    //   var vue = this;
    //   vue.dialogIni = true;
    //   if (
    //     vue.$store.state.cliusuario == null ||
    //     vue.$store.state.cliusuario == ""
    //   ) {
    //     vue.reloadPage();
    //     return;
    //   }
    //   vue.matricula = vue.$store.state.cliusuario;
    //   var Params = {
    //     matricula: vue.matricula,
    //     empresa: vue.$store.state.clicode,
    //   };
    //   axios
    //     .post("api/SistemaCobros/ConsultaRegistrosClient", Params)
    //     .then(function (response) {
    //       vue.nuevo = response.data;
    //       vue.nombre =
    //         vue.nuevo.nombre +
    //         " " +
    //         vue.nuevo.apellido_paterno +
    //         " " +
    //         vue.nuevo.apellido_materno;
    //       if (vue.nuevo.fecha_nacimiento.length > 9) {
    //         vue.fecha =
    //           vue.nuevo.fecha_nacimiento.substr(8, 2) +
    //           "/" +
    //           vue.nuevo.fecha_nacimiento.substr(5, 2) +
    //           "/" +
    //           vue.nuevo.fecha_nacimiento.substr(0, 4);
    //       } else {
    //         vue.fecha = "--/--/----";
    //       }
    //       vue.matricula = response.data.matricula;
    //       vue.dialogIni = false;
    //     })
    //     .catch(function (error) {
    //       vue.dialogIni = false;
    //       if (error.response) {
    //         if (error.response.status == 400) {
    //           vue.Message = error.response.data;
    //           vue.dialog404 = true;
    //         } else {
    //           vue.dialog500 = true;
    //         }
    //       } else {
    //         vue.dialog500 = true;
    //       }
    //       console.log(error);
    //     });
    // },
  },
  mounted() {
    this.$store.dispatch("clienteautologin");
    // this.cargartodo();
  },
};
</script>