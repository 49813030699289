<template>
  <v-row dense>
    <v-col dense cols="12" md="12">
      <v-toolbar flat dense>
        <v-switch
          label=""
          v-model="switch1"
          inset
          style="position: relative; top: 10px"
        >
        </v-switch>
        <div style="width: 20px"></div>
        <template v-if="switch1">
          <v-text-field
            :rules="[rules.required]"
            v-model="matricula"
            label="Busca Matrícula"
            append-icon="mdi-magnify"
            @click:append="cargarlupa"
            @keydown.enter="cargarlupa"
            block
            type="number"
            hide-details
          >
          </v-text-field>
          <div style="width: 10px"></div>
          <v-row style="position: relative; top: 15px">
            <v-col>
              <v-text-field
                :value="nombre"
                label="Nombre"
                rounded
                readonly
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-select
            v-model="matricula2"
            :items="itemsselect"
            label="Elige Alumno"
            single-line
            hide-details
            block
            @change="cargarselect"
            item-value="matricula"
          >
            <template slot="selection" slot-scope="data">
              <template v-if="data.item.grado != ''">
                {{ data.item.nombre }} {{ data.item.apellido_paterno }}
                {{ data.item.apellido_materno }} ({{
                  data.item.grado
                }})</template
              >
              <template v-else>
                {{ data.item.nombre }} {{ data.item.apellido_paterno }}
                {{ data.item.apellido_materno }}</template
              >
            </template>
            <template slot="item" slot-scope="data">
              <template v-if="data.item.grado != ''">
                {{ data.item.nombre }} {{ data.item.apellido_paterno }}
                {{ data.item.apellido_materno }} ({{
                  data.item.grado
                }})</template
              >
              <template v-else>
                {{ data.item.nombre }} {{ data.item.apellido_paterno }}
                {{ data.item.apellido_materno }}</template
              >
            </template>
          </v-select>
        </template>
      </v-toolbar>
    </v-col>
    <!-- <v-col dense cols="12" md="6">
        <v-text-field
          v-model="nombre"
          label="Nombre"
          rounded
          readonly
          :disabled="disabled"
        ></v-text-field>
      </v-col> -->
    <br />
    <br />
    <br />
    <v-col dense cols="12" md="6">
      <v-text-field
        v-model="nuevo.concepto"
        label="Suscripcion"
        rounded
        dense
        readonly
        :disabled="disabled"
      ></v-text-field>
    </v-col>
    <v-col dense cols="12" md="2">
      <v-text-field
        v-model="nuevo.deuda_anterior"
        label="Deuda Anterior"
        rounded
        dense
        small
        readonly
        prefix="$"
        :disabled="disabled"
      ></v-text-field>
    </v-col>
    <v-col dense cols="12" md="2">
      <v-text-field
        v-model="nuevo.deuda_actual"
        label="Deuda Actual"
        rounded
        dense
        small
        readonly
        prefix="$"
        :disabled="disabled"
      ></v-text-field>
    </v-col>
    <v-col dense cols="12" md="2">
      <v-text-field
        v-model="nuevo.deuda_total"
        label="Deuda Total"
        rounded
        dense
        small
        readonly
        prefix="$"
        :disabled="disabled"
      ></v-text-field>
    </v-col>
    <v-col dense cols="12">
      <v-data-table
        dense
        :headers="headers"
        :items="nuevo.suscripciones"
        :search="search"
        class="elevation-0"
        disable-sort
        :item-class="itemRowBackground"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-cash-register</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <template v-if="!disabled">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                block
              ></v-text-field>
              <v-divider class="mx-4" vertical></v-divider>
              <v-btn icon dense large text @click="excel">
                <v-icon title="Exportar Tabla">mdi-microsoft-excel</v-icon>
              </v-btn>
              <!-- <v-divider class="mx-4" vertical></v-divider> -->
              <v-dialog v-model="dialogextra" persistent max-width="800px">
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="program"
                      dark
                      class="mb-2"
                      :disabled="disabled"
                      v-bind="attrs"
                      v-on="on"
                      >Nuevo Pago</v-btn
                    >
                  </template> -->

                <v-card>
                  <v-card-title>
                    <span class="text-h5">Nuevo Pago</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col dense cols="12">
                          <v-form ref="formopt00">
                            <v-select
                              :rules="[rules.required]"
                              :items="opciones"
                              v-model="op"
                              label="Método de Pago"
                            ></v-select>
                          </v-form>
                        </v-col>
                        <v-col dense cols="12" md="6">
                          <v-form ref="formopt11">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="co"
                              label="Concepto"
                            ></v-text-field>
                          </v-form>
                        </v-col>
                        <v-col dense cols="12" md="6">
                          <v-form ref="formopt22">
                            <v-text-field
                              :rules="[rules.required, rules.importe]"
                              v-model="im"
                              label="Importe"
                            ></v-text-field>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="closefin">
                      Cancelar
                    </v-btn>
                    <v-btn color="program" text @click="pagarfin">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog" persistent max-width="800px">
                <!-- <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="program"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                    @click="butfecha"
                  >
                    Nueva Deuda
                  </v-btn>
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <template v-if="!disabled2">
                          <v-col dense cols="6">
                            <v-menu
                              v-model="menuDateBuscaB"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateFormattedMomentjsB"
                                  label="Fecha Inicial"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  :disabled="disabled2"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                show-current="true"
                                v-model="editedItem.fecha_inicial"
                                @change="fecha1"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col dense cols="6">
                            <v-menu
                              v-model="menuDateBuscaE"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateFormattedMomentjsE"
                                  label="Fecha Final"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="disabled2"
                                  locale="es"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                show-current="true"
                                v-model="editedItem.fecha_final"
                                @change="fecha2"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </template>
                        <v-col cols="6">
                          <v-form ref="formopt1">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.concepto"
                              label="Concepto"
                            ></v-text-field>
                          </v-form>
                        </v-col>
                        <v-col cols="6">
                          <v-form ref="formopt2">
                            <v-text-field
                              :rules="[rules.required, rules.importe]"
                              v-model="editedItem.importe"
                              label="Importe"
                            ></v-text-field>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="program" text @click="save"> Guardar </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialogDelete" max-width="400px">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text class="text-h6">
                    <h5>¿Seguro que deseas eliminar este elemento?</h5>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="program" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="program" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fecha_ini`]="{ item }">
          {{
            item.fecha_inicial.substr(8, 2) +
            "/" +
            item.fecha_inicial.substr(5, 2) +
            "/" +
            item.fecha_inicial.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          {{
            item.fecha_final.substr(8, 2) +
            "/" +
            item.fecha_final.substr(5, 2) +
            "/" +
            item.fecha_final.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_vig`]="{ item }">
          <template
            v-if="item.fecha_vigencia.length == 10 && item.actual == 'Deuda'"
          >
            {{
              item.fecha_vigencia.substr(8, 2) +
              "/" +
              item.fecha_vigencia.substr(5, 2) +
              "/" +
              item.fecha_vigencia.substr(0, 4)
            }}
          </template>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <template v-if="item.fecha_vigencia != '' && item.actual == 'Pago'">
            {{ item.fecha_vigencia }}
          </template>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <template
            v-if="
              item.estado == 'Pagado' ||
              item.estado == 'No Pagado' ||
              item.estado == '' ||
              item.estado == null
            "
          >
            {{ item.estado }}
          </template>
          <template v-else> ${{ item.estado }} </template>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <template v-if="item.actual == 'Deuda'">
            ${{ item.importe }}
          </template>
          <template v-if="item.actual == 'Pago'">
            -&nbsp;${{ item.importe }}
          </template>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row dense>
            <v-spacer></v-spacer>
            <v-icon title="Editar" @click="editItem1(item)">
              mdi-pencil
            </v-icon>
            <v-icon title="Eliminar" @click="deleteItem1(item)">
              mdi-delete
            </v-icon>
            <template v-if="item.actual == 'Deuda'">
              <v-icon @click="payItem1(item)" title="Pagar"> mdi-cash </v-icon>
            </template>
            <v-spacer></v-spacer>
          </v-row>
        </template>
        <template v-slot:no-data>
          <br />
          <p>No hay cargos recurrentes disponibles</p>
        </template>
      </v-data-table>
      <br />
      <v-data-table
        dense
        :headers="headers"
        :items="nuevo.cargos"
        :search="search"
        class="elevation-0"
        disable-sort
        :item-class="itemRowBackground"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
        hide-default-footer
        style="font-style: italic;"
      >
        <template v-slot:[`item.fecha_ini`]="{ item }">
          {{
            item.fecha_inicial.substr(8, 2) +
            "/" +
            item.fecha_inicial.substr(5, 2) +
            "/" +
            item.fecha_inicial.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_fin`]="{ item }">
          {{
            item.fecha_final.substr(8, 2) +
            "/" +
            item.fecha_final.substr(5, 2) +
            "/" +
            item.fecha_final.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.fecha_vig`]="{ item }">
          <template
            v-if="item.fecha_vigencia.length == 10 && item.actual == 'Deuda'"
          >
            {{
              item.fecha_vigencia.substr(8, 2) +
              "/" +
              item.fecha_vigencia.substr(5, 2) +
              "/" +
              item.fecha_vigencia.substr(0, 4)
            }}
          </template>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <template v-if="item.fecha_vigencia != '' && item.actual == 'Pago'">
            {{ item.fecha_vigencia }}
          </template>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <template
            v-if="
              item.estado == 'Pagado' ||
              item.estado == 'No Pagado' ||
              item.estado == '' ||
              item.estado == null
            "
          >
            {{ item.estado }}
          </template>
          <template v-else> ${{ item.estado }} </template>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <template v-if="item.actual == 'Deuda'">
            ${{ item.importe }}
          </template>
          <template v-if="item.actual == 'Pago'">
            -&nbsp;${{ item.importe }}
          </template>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row dense>
            <v-spacer></v-spacer>
            <v-icon title="Editar" @click="editItem2(item)">
              mdi-pencil
            </v-icon>
            <v-icon title="Eliminar" @click="deleteItem2(item)">
              mdi-delete
            </v-icon>
            <template v-if="item.actual == 'Deuda'">
              <v-icon @click="payItem2(item)" title="Pagar"> mdi-cash </v-icon>
            </template>
            <v-spacer></v-spacer>
          </v-row>
        </template>
        <template v-slot:no-data>
          <br />
          <p>No hay cargos únicos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogA" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Pago actualizado con exito.</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogA = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style>
.style-1 {
  color: rgb(0, 0, 0);
  background-color: rgb(226, 226, 226);
}
.style-2 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 238, 183);
}
.style-3 {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
.style-11 {
  color: rgb(0, 96, 0);
  background-color: rgb(226, 226, 226);
}
.style-22 {
  color: rgb(0, 96, 0);
  background-color: rgb(255, 238, 183);
}
.style-33 {
  color: rgb(0, 96, 0);
  background-color: rgb(255, 255, 255);
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    switch1: false,
    itemsselect: [],
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
      importe: (value) => {
        const pattern =
          /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
        return pattern.test(value) || "Importe inválido, ejemplo: 0.01";
      },
    },
    idfinal: "",
    actual: "",
    menuDateBuscaB: false,
    menuDateBuscaE: false,
    Message: "Internal Error",
    dialogA: false,
    dialogextra: false,
    dialog: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    opciones: ["Efectivo", "Transferencia"],
    nuevo: {
      id_cliente: "",
      matricula: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_inicial: "",
      fecha_final: "",
      deuda_anterior: "",
      deuda_actual: "",
      deuda_total: "",
      concepto: "",
      id_suscripcion: "",
      cargos: [],
      suscripciones: [],
    },
    vacio: {
      id_cliente: "",
      matricula: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_inicial: "",
      fecha_final: "",
      deuda_anterior: "",
      deuda_actual: "",
      deuda_total: "",
      concepto: "",
      cargos: [],
      suscripciones: [],
    },
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_registro",
        sortable: false,
      },
      { text: "Fecha Inicial", value: "fecha_ini", align: "center" },
      { text: "Fecha Final", value: "fecha_fin", align: "center" },
      { text: "Fecha Vigencia", value: "fecha_vig", align: "center" },
      { text: "Tipo", value: "tipo", align: "center" },
      { text: "Concepto", value: "concepto", align: "center" },
      { text: "Importe", value: "importe", align: "center" },
      { text: "Estado", value: "estado", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    disabled: true,
    disabled2: true,
    matricula: "",
    matricula2: {
      matricula: "",
      nombre: "",
    },
    co: "",
    op: "",
    im: "",
    con: {
      id_cargo: "",
      id_suscripcion: "",
      concepto: "",
    },
    listaid: [],
    listacli: [],
    editedIndex: -1,
    editedItem: {
      id_registro: "",
      id_cliente: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_vigencia: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      concepto: "",
      importe: "",
      actual: "",
      estado: "",
    },
    defaultItem: {
      id_registro: "",
      id_cliente: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_vigencia: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      concepto: "",
      importe: "",
      actual: "",
      estado: "",
    },
  }),

  computed: {
    formTitle() {
      //return this.editedIndex === -1 ? "Nueva Deuda" : "Editar";
      return "Editar";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.editedItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.fecha_inicial ? localLocale : "";
    },
    computedDateFormattedMomentjsE() {
      var localLocale = moment(this.editedItem.fecha_final).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.fecha_final ? localLocale : "";
    },
    nombre() {
      return (
        this.nuevo.nombre +
        " " +
        this.nuevo.apellido_paterno +
        " " +
        this.nuevo.apellido_materno
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {

    itemRowBackground: function (item) {
      var a = item.fecha_inicial;
      var b = item.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      hoy.setHours(0, 0, 0, 0);

      if (item.actual == "Pago") {
        if (hoy >= datf) {
          return "style-11";
        } else {
          if (hoy < dati) {
            return "style-33";
          } else {
            return "style-22";
          }
        }
      } else {
        if (hoy >= datf) {
          return "style-1";
        } else {
          if (hoy < dati) {
            return "style-3";
          } else {
            return "style-2";
          }
        }
      }
    },

    initialize() {
      this.nuevo.cargos = [];
      this.nuevo.suscripciones = [];
      this.matricula = "";
      this.cargarmat();
    },
    //
    editItem1(item) {
      this.disabled2 = false;
      this.editedIndex = this.nuevo.cargos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem1(item) {
      this.editedIndex = this.nuevo.cargos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    editItem2(item) {
      this.disabled2 = false;
      this.editedIndex = this.nuevo.suscripciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem2(item) {
      this.editedIndex = this.nuevo.suscripciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    payItem1(item) {
      this.con.id_suscripcion = item.id_suscripcion;
      this.con.id_cargo = item.id_cargo;
      this.con.concepto = item.concepto;
      this.dialogextra = true;
    },

    payItem2(item) {
      this.con.id_suscripcion = item.id_suscripcion;
      this.con.id_cargo = item.id_cargo;
      this.con.concepto = item.concepto;
      this.dialogextra = true;
    },

    payItem(item) {
      this.label0 = "registrar";
      this.label1 = item.concepto;
      this.label2 = item.importe;
      this.idfinal = item.id_registro;
      this.actual = "Pagado";
      this.dialog1 = true;
    },

    nopayItem(item) {
      this.label0 = "cancelar";
      this.label1 = item.concepto;
      this.label2 = item.importe;
      this.idfinal = item.id_registro;
      this.actual = "No Pagado";
      this.dialog1 = true;
    },

    deleteItemConfirm() {
      this.eliminar();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.formopt1.reset();
        this.$refs.formopt2.reset();
        this.fechasdef();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        if (this.$refs.formopt1) {
          this.$refs.formopt1.reset();
          this.$refs.formopt2.reset();
        }
        this.fechasdef();
      });
    },

    save() {
      if (this.$refs.formopt1.validate() && this.$refs.formopt2.validate()) {
        // if (this.editedIndex > -1) {
        this.editar();
        // } else {
        //   this.agregar();
        // }
      }
    },

    limpiar() {
      this.nuevo = this.vacio;
      this.disabled = true;
    },
    cargarselect() {
      this.matricula = this.matricula2;
      this.cargartodo();
    },
    cargarlupa() {
      this.matricula2 = this.matricula1;
      this.cargartodo();
    },
    cargartodo() {
      var vue = this;
      if (vue.matricula == "") {
        return;
      }
      vue.dialogIni = true;
      var Params = {
        matricula: vue.matricula,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      this.limpiar();
      axios
        .post("api/SistemaCobros/ConsultaRegistros", Params)
        .then(function (response) {
          vue.nuevo = response.data;
          vue.matricula = response.data.matricula;
          vue.disabled = false;
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.disabled = true;
        });
    },

    // agregar() {
    //   var vue = this;
    //   vue.dialogIni = true;
    //   var Params = {
    //     matricula: vue.matricula,
    //     id_cliente: vue.nuevo.id_cliente,
    //     concepto: vue.editedItem.concepto,
    //     importe: vue.editedItem.importe,
    //     empresa: vue.$store.state.empresa,
    //     u: vue.$store.state.usuario,
    //   };
    //   axios
    //     .post("api/SistemaCobros/InsertaRegistros", Params)
    //     .then(function (response) {
    //       vue.nuevo = response.data;
    //       vue.matricula = response.data.matricula;
    //       vue.disabled = false;
    //       vue.dialogIni = false;
    //       vue.close();
    //     })
    //     .catch(function (error) {
    //       vue.dialogIni = false;
    //       if (error.response) {
    //         if (error.response.status == 400) {
    //           vue.Message = error.response.data;
    //           vue.dialog404 = true;
    //         } else {
    //           vue.dialog500 = true;
    //         }
    //       } else {
    //         vue.dialog500 = true;
    //       }
    //       console.log(error);
    //       vue.disabled = true;
    //       vue.close();
    //     });
    // },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        matricula: vue.matricula,
        id_registro: vue.editedItem.id_registro,
        id_cliente: vue.nuevo.id_cliente,
        fecha_inicial: vue.editedItem.fecha_inicial,
        fecha_final: vue.editedItem.fecha_final,
        concepto: vue.editedItem.concepto,
        importe: vue.editedItem.importe,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ActualizaRegistros", Params)
        .then(function (response) {
          vue.nuevo = response.data;
          vue.matricula = response.data.matricula;
          vue.disabled = false;
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.disabled = true;
          vue.close();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        matricula: vue.matricula,
        id_registro: vue.editedItem.id_registro,
        id_cliente: vue.nuevo.id_cliente,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/DesactivaRegistros", Params)
        .then(function (response) {
          vue.nuevo = response.data;
          vue.matricula = response.data.matricula;
          vue.disabled = false;
          vue.dialogIni = false;
          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.disabled = true;
          vue.closeDelete();
        });
    },
    fechasdef() {
      let now = new Date();
      var newDate = new Date(now.setDate(now.getDate() + 1));
      this.editedItem.fecha_inicial = format(
        parseISO(now.toISOString()),
        "yyyy-MM-dd"
      );
      this.editedItem.fecha_final = format(
        parseISO(newDate.toISOString()),
        "yyyy-MM-dd"
      );
    },
    butfecha() {
      this.disabled2 = true;
      this.fecha1();
    },

    fecha1() {
      this.menuDateBuscaB = false;
      var a = this.editedItem.fecha_inicial;
      var b = this.editedItem.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      if (dati >= datf) {
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.editedItem.fecha_final = format(
          parseISO(newDate.toISOString()),
          "yyyy-MM-dd"
        );
      }
    },

    fecha2() {
      this.menuDateBuscaE = false;
      var a = this.editedItem.fecha_inicial;
      var b = this.editedItem.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      if (dati >= datf) {
        var newDate = new Date(datf.setDate(datf.getDate()));
        this.editedItem.fecha_inicial = format(
          parseISO(newDate.toISOString()),
          "yyyy-MM-dd"
        );
      }
    },

    pagarfin() {
      var z = this.$refs.formopt00.validate();
      var a = this.$refs.formopt11.validate();
      var b = this.$refs.formopt22.validate();
      if (z && a && b) {
        var vue = this;
        vue.dialogIni = true;
        var concept = vue.co;
        if (vue.con.id_suscripcion == "0") {
          concept = concept + " (" + vue.con.concepto + ")";
        }
        var Params = {
          matricula: vue.matricula,
          id_cliente: vue.nuevo.id_cliente,
          id_suscripcion: vue.con.id_suscripcion,
          id_cargo: vue.con.id_cargo,
          tipo: vue.op,
          concepto: concept,
          importe: vue.im,
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
        axios
          .post("api/SistemaCobros/InsertaRegistrosPago", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.closefin();
            vue.dialogA = true;
            vue.nuevo = response.data;
            vue.matricula = response.data.matricula;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            vue.closefin();
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    closefin() {
      this.$refs.formopt00.reset();
      this.$refs.formopt11.reset();
      this.$refs.formopt22.reset();
      this.dialogextra = false;
    },

    cargarmat() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        id: "0",
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      console.log(Params);
      axios
        .post("api/SistemaCobros/ConsultaClientesFiltro", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.itemsselect = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    excel() {
      this.dialogIni = true;
      var rows = [];
      rows.push([
        this.headers[0].text,
        this.headers[1].text,
        this.headers[2].text,
        this.headers[3].text,
        this.headers[4].text,
        this.headers[5].text,
        "Tipo",
        this.headers[6].text,
      ]);
      for (var i = 0; i < this.nuevo.suscripciones.length; i++) {
        var a =
          this.nuevo.suscripciones[i].fecha_vigencia.substr(8, 2) +
          "/" +
          this.nuevo.suscripciones[i].fecha_vigencia.substr(5, 2) +
          "/" +
          this.nuevo.suscripciones[i].fecha_vigencia.substr(0, 4);
        if ((a = "//")) {
          a = "";
        }
        rows.push([
          this.nuevo.suscripciones[i].id_registro,
          this.nuevo.suscripciones[i].fecha_inicial.substr(8, 2) +
            "/" +
            this.nuevo.suscripciones[i].fecha_inicial.substr(5, 2) +
            "/" +
            this.nuevo.suscripciones[i].fecha_inicial.substr(0, 4),
          this.nuevo.suscripciones[i].fecha_final.substr(8, 2) +
            "/" +
            this.nuevo.suscripciones[i].fecha_final.substr(5, 2) +
            "/" +
            this.nuevo.suscripciones[i].fecha_final.substr(0, 4),
          a,
          this.nuevo.suscripciones[i].concepto,
          this.nuevo.suscripciones[i].importe,
          this.nuevo.suscripciones[i].actual,
          this.nuevo.suscripciones[i].estado,
        ]);
      }
      rows.push([]);
      for (var i = 0; i < this.nuevo.cargos.length; i++) {
        var a =
          this.nuevo.cargos[i].fecha_vigencia.substr(8, 2) +
          "/" +
          this.nuevo.cargos[i].fecha_vigencia.substr(5, 2) +
          "/" +
          this.nuevo.cargos[i].fecha_vigencia.substr(0, 4);
        if ((a = "//")) {
          a = "";
        }
        rows.push([
          this.nuevo.cargos[i].id_registro,
          this.nuevo.cargos[i].fecha_inicial.substr(8, 2) +
            "/" +
            this.nuevo.cargos[i].fecha_inicial.substr(5, 2) +
            "/" +
            this.nuevo.cargos[i].fecha_inicial.substr(0, 4),
          this.nuevo.cargos[i].fecha_final.substr(8, 2) +
            "/" +
            this.nuevo.cargos[i].fecha_final.substr(5, 2) +
            "/" +
            this.nuevo.cargos[i].fecha_final.substr(0, 4),
          a,
          this.nuevo.cargos[i].concepto,
          this.nuevo.cargos[i].importe,
          this.nuevo.cargos[i].actual,
          this.nuevo.cargos[i].estado,
        ]);
      }
      var encoding = "data:text/csv;charset=utf-8,";
      var csvContent = "";
      rows.forEach(function (rowArray) {
        var row = rowArray.join(",");
        csvContent += row + "\r\n";
      });
      // console.log(csvContent);
      // var encodedUri = encodeURI(csvContent);
      var encodedUri = escape(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encoding + encodedUri);
      link.setAttribute("download", "Pagos.csv");
      document.body.appendChild(link);
      link.click();
      this.dialogIni = false;
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.editedItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.fecha_final).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
  },
};
</script>