<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        disable-sort
        sort-by="id_historial"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-book-open-variant</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-menu
              v-model="menuDateBuscaA"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjsA"
                  label="Fecha de Busqueda Inicial: "
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  locale="es"
                  class="tam"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha1"
                @change="funfecha1"
              ></v-date-picker>
            </v-menu>
            <v-divider class="mx-4" vertical></v-divider>
            <v-checkbox v-model="check"></v-checkbox>
            <v-menu
              v-model="menuDateBuscaB"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjsB"
                  label="Fecha de Busqueda Final: "
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  locale="es"
                  :disabled="!check"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha2"
                @change="funfecha2"
              ></v-date-picker>
            </v-menu>
          </v-toolbar>
        </template>
        <template v-slot:[`item.id_historial`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogA" max-width="400">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>
              Solo se puede buscar el historial del dia de hoy o de fechas
              anteriores.
            </h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogA = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogB" max-width="400">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>
              Solo se puede buscar el historial cuando la fecha inicial es menor
              o igual que la fecha final.
            </h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogB = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col></v-row
  >
</template>

<style>
.tam {
  width: 200px;
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    search: "",
    Message: "Internal Error",
    menuDateBuscaA: false,
    menuDateBuscaB: false,
    fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    fecha2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    check: false,
    dialogA: false,
    dialogB: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_historial",
      },
      { text: "Usuario", value: "usuario", align: "center" },
      { text: "Acción", value: "accion", align: "center" },
      { text: "Fecha", value: "datos", align: "center" },
    ],
    items: [],
    editedItem: {
      id_historial: "",
      usuario: "",
      accion: "",
      datos: "",
    },
  }),

  computed: {
    computedDateFormattedMomentjsA() {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha1 ? localLocale : "";
    },
    computedDateFormattedMomentjsB() {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
      return this.fecha2 ? localLocale : "";
    },
  },
  watch: {
    check(val) {
      val || this.closef();
    },
  },
  methods: {
    initialize() {
      this.items = [];
      this.cargartodo();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      this.items = [];
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        fecha_inicial: vue.fecha1,
        fecha_final: vue.fecha2,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaHistorial", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.dialogIni = false;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },
    funfecha1() {
      this.menuDateBuscaA = false;
      if (!this.check) {
        this.fecha2 = this.fecha1;
      }
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < dati) {
        this.dialogA = true;
        this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.fecha2 = this.fecha1;
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.cargartodo();
    },

    funfecha2() {
      this.menuDateBuscaB = false;
      var a = this.fecha1;
      var b = this.fecha2;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let hoy = new Date();
      if (hoy < datf) {
        this.dialogA = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      } else if (dati > datf) {
        this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      this.cargartodo();
    },
    closef() {
      var a = this.fecha1;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(dati.setDate(dati.getDate()));
      this.fecha2 = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      this.cargartodo();
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.fecha1).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.fecha2).format("DD MMM YYYY");
      moment.locale("es");
    }
  },
};
</script>