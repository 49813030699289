import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pagos from '../components/Pagos.vue'
import Administradores from '../components/Administradores.vue'
import Usuarios from '../components/Usuarios.vue'
import Clientes from '../components/Clientes.vue'
import Suscripciones from '../components/Suscripciones.vue'
import Cargos from '../components/Cargos.vue'
import Empresa from '../components/Empresa.vue'
import Historial from '../components/Historial.vue'
import Login from '../components/Login.vue'
import CliLogin from '../components/CliLogin.vue'
import CliPagar from '../components/CliPagar.vue'
import Reportes from '../components/Reportes.vue'
import Gastos from '../components/Gastos.vue'
import Actividades from '../components/Actividades.vue'
import Menu from '../components/Menu.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/clilogin',
        name: 'CliLogin',
        component: CliLogin
    },
    {
        path: '/clipagar',
        name: 'CliPagar',
        component: CliPagar
    },
    {
        path: '/pagos',
        name: 'Pagos',
        component: Pagos
    },
    {
        path: '/administradores',
        name: 'Administradores',
        component: Administradores
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: Usuarios
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes
    },
    {
        path: '/suscripciones',
        name: 'Suscripciones',
        component: Suscripciones
    },
    {
        path: '/cargos',
        name: 'Cargos',
        component: Cargos
    },
    {
        path: '/empresa',
        name: 'Empresa',
        component: Empresa
    },
    {
        path: '/historial',
        name: 'Historial',
        component: Historial
    },
    {
        path: '/reportes',
        name: 'Reportes',
        component: Reportes
    },
    {
        path: '/gastos',
        name: 'Gastos',
        component: Gastos
    },
    {
        path: '/actividades',
        name: 'Actividades',
        component: Actividades
    },
    {
        path: '/menu',
        name: 'Menu',
        component: Menu
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router