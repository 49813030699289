<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :search="search"
        sort-by="matricula"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas or página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-card-account-details</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              block
            ></v-text-field>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon dense large text @click="excel">
              <v-icon title="Exportar Tabla">mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn color="program" dark class="mb-2" @click="dialog = true">
              Nuevo
            </v-btn>
            <v-dialog v-model="dialog" persistent max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Nuevo</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row dense>
                      <v-col cols="4">
                        <v-form ref="form1">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.nombre"
                            label="Nombre"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="form2">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.apellido_paterno"
                            label="Apellido Paterno"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="form3">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.apellido_materno"
                            label="Apellido Materno"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                          v-model="menuDateBuscaNac0"
                          :close-on-content-click="false"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedDateNacimiento0"
                              label="Fecha de Nacimiento"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              locale="es"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :rules="[rules.required]"
                            locale="es"
                            v-model="newItem.fecha_nacimiento"
                            @change="menuDateBuscaNac0 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-form ref="form4">
                          <v-select
                            :items="genero"
                            no-data-text="Sin datos disponibles"
                            :rules="[rules.required]"
                            v-model="newItem.sexo"
                            label="Sexo"
                            dense
                          ></v-select>
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-form ref="form5">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.telefono"
                            label="Telefono"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-form ref="form6">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.correo"
                            label="Correo"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="1">
                        <v-icon
                          dark
                          large
                          title="Generar Matrícula"
                          @click="matriculanew"
                          color="program"
                          >mdi-earth-arrow-right</v-icon
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-form ref="form7">
                          <v-text-field
                            :rules="[rules.required, rules.int]"
                            v-model="newItem.matricula"
                            label="Matricula"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>

                      <v-col cols="6">
                        <v-form ref="form8">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="newItem.pass"
                            type="password"
                            label="Contraseña"
                            dense
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="form9">
                          <v-select
                            :items="itemsus"
                            no-data-text="Sin datos disponibles"
                            :rules="[rules.required]"
                            v-model="newItem.id_suscripcion"
                            label="Suscripcion"
                            dense
                            item-value="id_suscripcion"
                            @change="insus"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.concepto }}
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.concepto }}
                            </template>
                          </v-select>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-menu
                          v-model="menuDateBuscaIni0"
                          :close-on-content-click="false"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedDateInicial0"
                              label="Fecha Inicial"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              locale="es"
                              :disabled="disabledfun"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :rules="[rules.required]"
                            locale="es"
                            show-current="true"
                            v-model="newItem.fecha_inicial"
                            @change="fecha1"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-menu
                          v-model="menuDateBuscaFin0"
                          :close-on-content-click="false"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedDateFinal0"
                              label="Fecha Final"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              locale="es"
                              :disabled="disabledfun"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :rules="[rules.required]"
                            locale="es"
                            show-current="true"
                            v-model="newItem.fecha_final"
                            @change="fecha2"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="agregar"> Guardar </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEdit" persistent max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Editar</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeedit"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-carousel
                    :dark="$vuetify.theme.dark"
                    :light="!$vuetify.theme.dark"
                    hide-delimiter-background
                    :continuous="false"
                    show-arrows-on-hover
                    height="auto"
                    v-model="carousel"
                  >
                    <!--DATOS GENERALES-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos generales</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.nombre"
                              label="Nombre"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.apellido_paterno"
                              label="Apellido Paterno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.apellido_materno"
                              label="Apellido Materno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-menu
                              v-model="menuDateBuscaNac"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateNacimiento"
                                  label="Fecha de Nacimiento"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                v-model="editedItem.fecha_nacimiento"
                                @change="menuDateBuscaNac = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              :items="genero"
                              no-data-text="Sin datos disponibles"
                              :rules="[rules.required]"
                              v-model="editedItem.sexo"
                              label="Sexo"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.telefono"
                              label="Telefono"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.correo"
                              label="Correo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1">
                            <v-icon
                              dark
                              large
                              title="Generar Matrícula"
                              @click="matriculanew"
                              color="program"
                              >mdi-earth-arrow-right</v-icon
                            >
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              :rules="[rules.required, rules.int]"
                              v-model="editedItem.matricula"
                              label="Matricula"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :rules="[rules.required]"
                              v-model="editedItem.pass"
                              type="password"
                              label="Contraseña"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              :items="itemsus"
                              no-data-text="Sin datos disponibles"
                              :rules="[rules.required]"
                              v-model="editedItem.id_suscripcion"
                              label="Suscripción"
                              dense
                              item-value="id_suscripcion"
                              disabled
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.concepto }}
                              </template>
                              <template slot="item" slot-scope="data">
                                {{ data.item.concepto }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              v-model="menuDateBuscaIni"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateInicial"
                                  label="Fecha Inicial"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  disabled
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                show-current="true"
                                v-model="editedItem.fecha_inicial"
                                @change="fecha1"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="4">
                            <v-menu
                              v-model="menuDateBuscaFin"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateFinal"
                                  label="Fecha Final"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  disabled
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                show-current="true"
                                v-model="editedItem.fecha_final"
                                @change="fecha2"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS ALUMNO-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos escolares</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-select
                              v-model="editedItem.grado"
                              label="Grado"
                              dense
                              :items="itemsgr"
                              no-data-text="Sin datos disponibles"
                              item-value="id_grado"
                            >
                              <template slot="selection" slot-scope="data">
                                {{ data.item.concepto }}
                              </template>
                              <template slot="item" slot-scope="data">
                                {{ data.item.concepto }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.grupo"
                              label="Grupo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.nia"
                              label="NIA"
                              dense
                            ></v-text-field>
                          </v-col> </v-row
                        ><br />
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Domicilio en el cual habita</h2>
                          <v-spacer></v-spacer> </v-row
                        ><br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.residencia_calle"
                              label="Calle"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.residencia_numero"
                              label="Número Exterior"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.residencia_interior"
                              label="Número Interior (opcional)"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.residencia_colonia"
                              label="Colonia"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.residencia_cp"
                              label="CP"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.residencia_localidad"
                              label="Localidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.residencia_municipio"
                              label="Municipio"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS ESPECIFICOS-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos específicos del alumno</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.curp"
                              label="CURP"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.tipo_sangre"
                              label="Tipo de sangre"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.nacimiento_municipio"
                              label="Municipio de nacimiento"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.nacimiento_entidad"
                              label="Entidad de nacimiento"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.nacimiento_pais"
                              label="Pais de nacimiento"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.nacimiento_acta"
                              label="Número de acta de nacimiento"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.nacimiento_libro"
                              label="Número de libro en el acta"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.anio_registro"
                              label="Año de registro"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.crip"
                              label="CRIP (En el acta de nacimiento)"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS MADRE-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos de la madre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_nombre"
                              label="Nombre"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_apellido_paterno"
                              label="Apellido Paterno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_apellido_materno"
                              label="Apellido Materno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-menu
                              v-model="menuDateBuscaMad"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDateMadre"
                                  label="Fecha de Nacimiento"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                v-model="editedItem.madre_fecha_nacimiento"
                                @change="menuDateBuscaMad = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_curp"
                              label="CURP"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_rfc"
                              label="RFC"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_nacionalidad"
                              label="Nacionalidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.madre_viveconalumno"
                              label="Vive con el Alumno"
                              dense
                              @change="madrecopy"
                              :items="dicotomicas"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Domicilio de la madre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_residencia_calle"
                              label="Calle"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_residencia_numero"
                              label="Número Exterior"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_residencia_interior"
                              label="Número Interior (opcional)"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_colonia"
                              label="Colonia"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_cp"
                              label="Código Postal"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_localidad"
                              label="Localidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_municipio"
                              label="Municipio"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_entidad"
                              label="Entidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_residencia_pais"
                              label="País"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos de la madre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_telefono"
                              label="Telefono"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_celular"
                              label="Celular"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.madre_trabajo_telefono"
                              label="Telefono de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_trabajo_horario"
                              label="Horario de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_trabajo_correo"
                              label="Correo de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.madre_trabajo_domicilio"
                              label="Domicilio de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_ocupacion"
                              label="Ocupación"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.madre_estudios"
                              label="Estudios"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS PADRE-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos del padre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_nombre"
                              label="Nombre"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_apellido_paterno"
                              label="Apellido Paterno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_apellido_materno"
                              label="Apellido Materno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-menu
                              v-model="menuDateBuscaPad"
                              :close-on-content-click="false"
                              max-width="290"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :value="computedDatePadre"
                                  label="Fecha de Nacimiento"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  locale="es"
                                  dense
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :rules="[rules.required]"
                                locale="es"
                                v-model="editedItem.padre_fecha_nacimiento"
                                @change="menuDateBuscaPad = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_curp"
                              label="CURP"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_rfc"
                              label="RFC"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_nacionalidad"
                              label="Nacionalidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.padre_viveconalumno"
                              label="Vive con el Alumno"
                              dense
                              @change="padrecopy"
                              :items="dicotomicas"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos del padre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_residencia_calle"
                              label="Calle"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_residencia_numero"
                              label="Número Exterior"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_residencia_interior"
                              label="Número Interior (opcional)"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_colonia"
                              label="Colonia"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_cp"
                              label="Código Postal"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_localidad"
                              label="Localidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_municipio"
                              label="Municipio"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_entidad"
                              label="Entidad"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_residencia_pais"
                              label="País"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos del padre</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_telefono"
                              label="Telefono"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_celular"
                              label="Celular"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.padre_trabajo_telefono"
                              label="Telefono de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_trabajo_horario"
                              label="Horario de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_trabajo_correo"
                              label="Correo de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.padre_trabajo_domicilio"
                              label="Domicilio de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_ocupacion"
                              label="Ocupación"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.padre_estudios"
                              label="Estudios"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS PARA EL REGISTRO DE LA SEP-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos para el registro de la SEP</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_nombre"
                              label="Nombre del Tutor"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_apellido_paterno"
                              label="Apellido Paterno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_apellido_materno"
                              label="Apellido Materno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.tutor_viveconalumno"
                              label="Vive con el Alumno"
                              dense
                              :items="dicotomicas"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_telefono"
                              label="Telefono"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_celular"
                              label="Celular"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.tutor_trabajo_telefono"
                              label="Telefono de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.tutor_trabajo_horario"
                              label="Horario de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.tutor_trabajo_correo"
                              label="Correo de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.tutor_trabajo_domicilio"
                              label="Domicilio de Trabajo"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--PERSONAS AUTORIZADAS A RECOGER AL MENOR-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos autorizadas a recoger al menor</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar1_nombre"
                              label="Nombre Completo del Familiar"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar1_parentesco"
                              label="Parentesco o Relación"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar2_nombre"
                              label="Nombre Completo del Familiar"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar2_parentesco"
                              label="Parentesco o Relación"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar3_nombre"
                              label="Nombre Completo del Familiar"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.familiar3_parentesco"
                              label="Parentesco o Relación"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--DATOS DEL NACIMIENTO-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Datos del nacimiento</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.salud_numero_embarazo"
                              label="Número de Embarazo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.salud_duracion_embarazo"
                              label="Duración de Embarazo"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.salud_complicaciones_embarazo"
                              label="Complicaciones"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <br />
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Condiciones al nacer</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="4">
                            <v-select
                              v-model="editedItem.salud_llanto_inmediato"
                              label="Llanto inmediato"
                              dense
                              :items="dicotomicas"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-select
                              v-model="editedItem.salud_anoxia"
                              label="Anoxia"
                              dense
                              :items="dicotomicas"
                            ></v-select>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="editedItem.salud_apgar"
                              label="Calificación APGAR"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_peso_nacer"
                              label="Peso al nacer"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_talla"
                              label="Talla"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_accidentes"
                              label="Accidentes graves que deban ser reportados"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_problemas"
                              label="Problemas de salud que deban ser reportados"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--SALUD Y ALIMENTACION-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Salud y alimentación</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="6">
                            <v-select
                              v-model="editedItem.salud_pecho"
                              label="Pecho"
                              :items="dicotomicas"
                              dense
                            ></v-select>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_retiro_pecho"
                              label="Edad y Causa del Retiro"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_alergias"
                              label="Alergias, Intolerancias y Alimentos No Permitidos"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_alimentacion"
                              label="Rechazo o Dificultad Para Comer Algún Alimento"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_enfermedades"
                              label="Enfermedades importantes padecidas"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.salud_tratamiento"
                              label="Algún tratamiento médico actual"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                    <!--RUTINA E HISTORIA ACADEMICA-->
                    <v-carousel-item>
                      <v-container>
                        <v-row dense
                          ><v-spacer></v-spacer>
                          <h2>Rutina e historia académica</h2>
                          <v-spacer></v-spacer>
                        </v-row>
                        <br />
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.escuela_rutina"
                              label="Describir rutina general del alumno"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.escuela_procedencia"
                              label="Escuela de procedencia"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.escuela_grados"
                              label="Grados cursados en ella"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.escuela_promedio"
                              label="Promedio general del ciclo escolar anterior"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItem.escuela_motivo"
                              label="Motivo del cambio de colegio"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.opiniones"
                              label="Algún evento importante que influya al menor"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col></v-col>
                        </v-row>
                      </v-container>
                    </v-carousel-item>
                  </v-carousel>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="editar"> Guardar </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-h6">
                  <h5>¿Seguro que deseas eliminar este elemento?</h5>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="program" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.nombre`]="{ item }">
          {{
            item.nombre +
            " " +
            item.apellido_paterno +
            " " +
            item.apellido_materno
          }}
        </template>
        <template v-slot:[`item.fecha_nacimiento`]="{ item }">
          {{
            item.fecha_nacimiento.substr(8, 2) +
            "/" +
            item.fecha_nacimiento.substr(5, 2) +
            "/" +
            item.fecha_nacimiento.substr(0, 4)
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Editar" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon title="Eliminar" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col></v-row
  >
</template>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import Vue from "vue";
export default {
  data: () => ({
    search: "",
    rules: {
      required: (value) => !!value || "Requerido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo invalido";
      },
      int: (value) => {
        const pattern = /^([0]|[1-9][0-9]*)$/;
        return pattern.test(value) || "Número Invalido";
      },
    },
    menuDateBuscaNac0: false,
    menuDateBuscaIni0: false,
    menuDateBuscaFin0: false,
    menuDateBuscaNac: false,
    menuDateBuscaIni: false,
    menuDateBuscaFin: false,
    menuDateBuscaMad: false,
    menuDateBuscaPad: false,
    Message: "Internal Error",
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    headers: [
      // {
      //   text: "ID",
      //   align: "start",
      //   value: "id_cliente",
      // },
      { text: "Nombre", value: "nombre", align: "center" },
      { text: "Matricula", value: "matricula", align: "center" },
      { text: "Sexo", value: "sexo", align: "center" },
      {
        text: "Fecha de Nacimiento",
        value: "fecha_nacimiento",
        align: "center",
      },
      { text: "Correo", value: "correo", align: "center" },
      { text: "Telefono", value: "telefono", align: "center" },
      { text: "Grado", value: "grado", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    itemsus: [],
    itemsgr: [],
    genero: ["Masculino", "Femenino"],
    dicotomicas: ["Si", "No"],
    carousel: 0,
    newItem: {
      id_cliente: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      sexo: "",
      correo: "",
      telefono: "",
      id_suscripcion: "",
      matricula: "",
      pass: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    },
    defaultNew: {
      id_cliente: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      sexo: "",
      correo: "",
      telefono: "",
      id_suscripcion: "",
      matricula: "",
      pass: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    },
    editedItem: {
      id_cliente: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      sexo: "",
      correo: "",
      telefono: "",
      id_suscripcion: "",
      matricula: "",
      pass: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      //DATOS DEL ALUMNO
      grado: "",
      grupo: "",
      nia: "",
      residencia_calle: "",
      residencia_numero: "",
      residencia_interior: "",
      residencia_colonia: "",
      residencia_cp: "",
      residencia_localidad: "",
      residencia_municipio: "",
      //DATOS ESPECIFICOS
      nacimiento_municipio: "",
      nacimiento_entidad: "",
      nacimiento_pais: "",
      nacimiento_acta: "",
      nacimiento_libro: "",
      anio_registro: "",
      crip: "",
      curp: "",
      tipo_sangre: "",
      //DATOS DE LA MADRE
      madre_nombre: "",
      madre_apellido_paterno: "",
      madre_apellido_materno: "",
      madre_fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      madre_curp: "",
      madre_rfc: "",
      madre_nacionalidad: "",

      madre_residencia_calle: "",
      madre_residencia_numero: "",
      madre_residencia_interior: "",
      madre_residencia_colonia: "",
      madre_residencia_cp: "",
      madre_residencia_localidad: "",
      madre_residencia_municipio: "",
      madre_residencia_entidad: "",
      madre_residencia_pais: "",

      madre_telefono: "",
      madre_celular: "",
      madre_trabajo_telefono: "",
      madre_trabajo_horario: "",
      madre_trabajo_correo: "",
      madre_trabajo_domicilio: "",
      madre_ocupacion: "",
      madre_estudios: "",
      madre_viveconalumno: "",
      //DATOS DEL PADRE
      padre_nombre: "",
      padre_apellido_paterno: "",
      padre_apellido_materno: "",
      padre_fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      padre_curp: "",
      padre_rfc: "",
      padre_nacionalidad: "",

      padre_residencia_calle: "",
      padre_residencia_numero: "",
      padre_residencia_interior: "",
      padre_residencia_colonia: "",
      padre_residencia_cp: "",
      padre_residencia_localidad: "",
      padre_residencia_municipio: "",
      padre_residencia_entidad: "",
      padre_residencia_pais: "",

      padre_telefono: "",
      padre_celular: "",
      padre_trabajo_telefono: "",
      padre_trabajo_horario: "",
      padre_trabajo_correo: "",
      padre_trabajo_domicilio: "",
      padre_ocupacion: "",
      padre_estudios: "",
      padre_viveconalumno: "",
      //DATOS PARA EL REGISTRO DE LA SEP
      tutor_nombre: "",
      tutor_apellido_paterno: "",
      tutor_apellido_materno: "",
      tutor_telefono: "",
      tutor_celular: "",
      tutor_trabajo_telefono: "",
      tutor_trabajo_horario: "",
      tutor_trabajo_correo: "",
      tutor_trabajo_domicilio: "",
      tutor_viveconalumno: "",
      //PERSONAS AUTORIZADAS A RECOGER AL MENOR
      familiar1_nombre: "",
      familiar1_parentesco: "",
      familiar2_nombre: "",
      familiar2_parentesco: "",
      familiar3_nombre: "",
      familiar3_parentesco: "",
      //DATOS DEL NACIMIENTO
      salud_numero_embarazo: "",
      salud_duracion_embarazo: "",
      salud_complicaciones_embarazo: "",
      salud_llanto_inmediato: "",
      salud_anoxia: "",
      salud_peso_nacer: "",
      salud_talla: "",
      salud_apgar: "",
      salud_accidentes: "",
      salud_problemas: "",
      //SALUD Y ALIMENTACION
      salud_pecho: "",
      salud_retiro_pecho: "",
      salud_alergias: "",
      salud_alimentacion: "",
      salud_enfermedades: "",
      salud_tratamiento: "",
      //RUTINA E HISTORIA ACADEMICA
      escuela_rutina: "",
      escuela_procedencia: "",
      escuela_grados: "",
      escuela_promedio: "",
      escuela_motivo: "",
      opiniones: "",
    },
    defaultItem: {
      id_cliente: "",
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      sexo: "",
      correo: "",
      telefono: "",
      id_suscripcion: "",
      matricula: "",
      pass: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      //DATOS DEL ALUMNO
      grado: "",
      grupo: "",
      nia: "",
      residencia_calle: "",
      residencia_numero: "",
      residencia_interior: "",
      residencia_colonia: "",
      residencia_cp: "",
      residencia_localidad: "",
      residencia_municipio: "",
      //DATOS ESPECIFICOS
      nacimiento_municipio: "",
      nacimiento_entidad: "",
      nacimiento_pais: "",
      nacimiento_acta: "",
      nacimiento_libro: "",
      anio_registro: "",
      crip: "",
      curp: "",
      tipo_sangre: "",
      //DATOS DE LA MADRE
      madre_nombre: "",
      madre_apellido_paterno: "",
      madre_apellido_materno: "",
      madre_fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      madre_curp: "",
      madre_rfc: "",
      madre_nacionalidad: "",

      madre_residencia_calle: "",
      madre_residencia_numero: "",
      madre_residencia_interior: "",
      madre_residencia_colonia: "",
      madre_residencia_cp: "",
      madre_residencia_localidad: "",
      madre_residencia_municipio: "",
      madre_residencia_entidad: "",
      madre_residencia_pais: "",

      madre_telefono: "",
      madre_celular: "",
      madre_trabajo_telefono: "",
      madre_trabajo_horario: "",
      madre_trabajo_correo: "",
      madre_trabajo_domicilio: "",
      madre_ocupacion: "",
      madre_estudios: "",
      madre_viveconalumno: "",
      //DATOS DEL PADRE
      padre_nombre: "",
      padre_apellido_paterno: "",
      padre_apellido_materno: "",
      padre_fecha_nacimiento: format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      ),
      padre_curp: "",
      padre_rfc: "",
      padre_nacionalidad: "",

      padre_residencia_calle: "",
      padre_residencia_numero: "",
      padre_residencia_interior: "",
      padre_residencia_colonia: "",
      padre_residencia_cp: "",
      padre_residencia_localidad: "",
      padre_residencia_municipio: "",
      padre_residencia_entidad: "",
      padre_residencia_pais: "",

      padre_telefono: "",
      padre_celular: "",
      padre_trabajo_telefono: "",
      padre_trabajo_horario: "",
      padre_trabajo_correo: "",
      padre_trabajo_domicilio: "",
      padre_ocupacion: "",
      padre_estudios: "",
      padre_viveconalumno: "",
      //DATOS PARA EL REGISTRO DE LA SEP
      tutor_nombre: "",
      tutor_apellido_paterno: "",
      tutor_apellido_materno: "",
      tutor_telefono: "",
      tutor_celular: "",
      tutor_trabajo_telefono: "",
      tutor_trabajo_horario: "",
      tutor_trabajo_correo: "",
      tutor_trabajo_domicilio: "",
      tutor_viveconalumno: "",
      //PERSONAS AUTORIZADAS A RECOGER AL MENOR
      familiar1_nombre: "",
      familiar1_parentesco: "",
      familiar2_nombre: "",
      familiar2_parentesco: "",
      familiar3_nombre: "",
      familiar3_parentesco: "",
      //DATOS DEL NACIMIENTO
      salud_numero_embarazo: "",
      salud_duracion_embarazo: "",
      salud_complicaciones_embarazo: "",
      salud_llanto_inmediato: "",
      salud_anoxia: "",
      salud_peso_nacer: "",
      salud_talla: "",
      salud_apgar: "",
      salud_accidentes: "",
      salud_problemas: "",
      //SALUD Y ALIMENTACION
      salud_pecho: "",
      salud_retiro_pecho: "",
      salud_alergias: "",
      salud_alimentacion: "",
      salud_enfermedades: "",
      salud_tratamiento: "",
      //RUTINA E HISTORIA ACADEMICA
      escuela_rutina: "",
      escuela_procedencia: "",
      escuela_grados: "",
      escuela_promedio: "",
      escuela_motivo: "",
      opiniones: "",
    },
  }),

  computed: {
    computedDateNacimiento0() {
      var localLocale = moment(this.newItem.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.newItem.fecha_nacimiento ? localLocale : "";
    },
    computedDateInicial0() {
      var localLocale = moment(this.newItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.newItem.fecha_inicial ? localLocale : "";
    },
    computedDateFinal0() {
      var localLocale = moment(this.newItem.fecha_final).format("DD MMM YYYY");
      moment.locale("es");
      return this.newItem.fecha_final ? localLocale : "";
    },
    computedDateNacimiento() {
      var localLocale = moment(this.editedItem.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.fecha_nacimiento ? localLocale : "";
    },
    computedDateInicial() {
      var localLocale = moment(this.editedItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.fecha_inicial ? localLocale : "";
    },
    computedDateFinal() {
      var localLocale = moment(this.editedItem.fecha_final).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.fecha_final ? localLocale : "";
    },
    computedDateMadre() {
      var localLocale = moment(this.editedItem.madre_fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.madre_fecha_nacimiento ? localLocale : "";
    },
    computedDatePadre() {
      var localLocale = moment(this.editedItem.padre_fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
      return this.editedItem.padre_fecha_nacimiento ? localLocale : "";
    },
    disabledfun() {
      if (this.newItem.id_suscripcion) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    initialize() {
      this.items = [];
      this.itemsus = [];
      this.itemsgr = [];
      this.cargartodo();
      this.cargarsus();
      this.cargargr();
    },

    editItem(item) {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id: item.id_cliente,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaClientesDetalles", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.editedItem = response.data;
          vue.dialogEdit = true;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    deleteItem(item) {
      this.editedItem.id_cliente = item.id_cliente;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.eliminar();
      this.closeDelete();
    },

    close() {
      this.newItem.fecha_nacimiento = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.newItem.fecha_inicial = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.newItem.fecha_final = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.dialog = false;
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.form3.reset();
      this.$refs.form4.reset();
      this.$refs.form5.reset();
      this.$refs.form6.reset();
      this.$refs.form7.reset();
      this.$refs.form8.reset();
      this.$refs.form9.reset();
    },

    closeedit() {
      this.carousel = 0;
      this.editedItem = this.defaultItem;
      this.dialogEdit = false;
    },

    closeDelete() {
      this.editedItem = this.defaultItem;
      this.dialogDelete = false;
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaClientes", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.items = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    cargarsus() {
      var vue = this;
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaSuscripciones", Params)
        .then(function (response) {
          vue.itemsus = response.data;
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    cargargr() {
      var vue = this;
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ConsultaGrados", Params)
        .then(function (response) {
          vue.itemsgr = response.data;
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    agregar() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      var c = this.$refs.form3.validate();
      var d = this.$refs.form4.validate();
      var e = this.$refs.form5.validate();
      var f = this.$refs.form6.validate();
      var g = this.$refs.form7.validate();
      var h = this.$refs.form8.validate();
      var i = this.$refs.form9.validate();
      if (a && b && c && d && e && f && g && h && i) {
        var vue = this;
        vue.dialogIni = true;
        var Params = {
          nombre: vue.newItem.nombre,
          apellido_paterno: vue.newItem.apellido_paterno,
          apellido_materno: vue.newItem.apellido_materno,
          fecha_nacimiento: vue.newItem.fecha_nacimiento,
          sexo: vue.newItem.sexo,
          correo: vue.newItem.correo,
          telefono: vue.newItem.telefono,
          matricula: vue.newItem.matricula,
          pass: vue.newItem.pass,
          id_suscripcion: vue.newItem.id_suscripcion,
          fecha_inicial: vue.newItem.fecha_inicial,
          fecha_final: vue.newItem.fecha_final,
          empresa: vue.$store.state.empresa,
          u: vue.$store.state.usuario,
        };
        axios
          .post("api/SistemaCobros/InsertaClientes", Params)
          .then(function (response) {
            vue.dialogIni = false;
            vue.close();
            vue.items = response.data;
          })
          .catch(function (error) {
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data;
                if (vue.Message.length > 26) {
                  if (
                    vue.Message.substr(vue.Message.length - 26, 26) ==
                    "for key 'matricula_UNIQUE'"
                  ) {
                    vue.Message =
                      "Ya existe el valor de matrícula, favor de ingresar otro.";
                  }
                }
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      }
    },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_cliente: vue.editedItem.id_cliente,
        nombre: vue.editedItem.nombre,
        apellido_paterno: vue.editedItem.apellido_paterno,
        apellido_materno: vue.editedItem.apellido_materno,
        fecha_nacimiento: vue.editedItem.fecha_nacimiento,
        sexo: vue.editedItem.sexo,
        correo: vue.editedItem.correo,
        telefono: vue.editedItem.telefono,
        id_suscripcion: vue.editedItem.id_suscripcion,
        fecha_inicial: vue.editedItem.fecha_inicial,
        fecha_final: vue.editedItem.fecha_final,
        matricula: vue.editedItem.matricula,
        pass: vue.editedItem.pass,

        grado: vue.editedItem.grado,
        grupo: vue.editedItem.grupo,
        nia: vue.editedItem.nia,
        residencia_calle: vue.editedItem.residencia_calle,
        residencia_numero: vue.editedItem.residencia_numero,
        residencia_interior: vue.editedItem.residencia_interior,
        residencia_colonia: vue.editedItem.residencia_colonia,
        residencia_cp: vue.editedItem.residencia_cp,
        residencia_localidad: vue.editedItem.residencia_localidad,
        residencia_municipio: vue.editedItem.residencia_municipio,

        nacimiento_municipio: vue.editedItem.nacimiento_municipio,
        nacimiento_entidad: vue.editedItem.nacimiento_entidad,
        nacimiento_pais: vue.editedItem.nacimiento_pais,
        nacimiento_acta: vue.editedItem.nacimiento_acta,
        nacimiento_libro: vue.editedItem.nacimiento_libro,
        anio_registro: vue.editedItem.anio_registro,
        crip: vue.editedItem.crip,
        curp: vue.editedItem.curp,
        tipo_sangre: vue.editedItem.tipo_sangre,

        madre_nombre: vue.editedItem.madre_nombre,
        madre_apellido_paterno: vue.editedItem.madre_apellido_paterno,
        madre_apellido_materno: vue.editedItem.madre_apellido_materno,
        madre_fecha_nacimiento: vue.editedItem.madre_fecha_nacimiento,
        madre_curp: vue.editedItem.madre_curp,
        madre_rfc: vue.editedItem.madre_rfc,
        madre_nacionalidad: vue.editedItem.madre_nacionalidad,

        madre_residencia_calle: vue.editedItem.madre_residencia_calle,
        madre_residencia_numero: vue.editedItem.madre_residencia_numero,
        madre_residencia_interior: vue.editedItem.madre_residencia_interior,
        madre_residencia_colonia: vue.editedItem.madre_residencia_colonia,
        madre_residencia_cp: vue.editedItem.madre_residencia_cp,
        madre_residencia_localidad: vue.editedItem.madre_residencia_localidad,
        madre_residencia_municipio: vue.editedItem.madre_residencia_municipio,
        madre_residencia_entidad: vue.editedItem.madre_residencia_entidad,
        madre_residencia_pais: vue.editedItem.madre_residencia_pais,

        madre_telefono: vue.editedItem.madre_telefono,
        madre_celular: vue.editedItem.madre_celular,
        madre_trabajo_telefono: vue.editedItem.madre_trabajo_telefono,
        madre_trabajo_horario: vue.editedItem.madre_trabajo_horario,
        madre_trabajo_correo: vue.editedItem.madre_trabajo_correo,
        madre_trabajo_domicilio: vue.editedItem.madre_trabajo_domicilio,
        madre_ocupacion: vue.editedItem.madre_ocupacion,
        madre_estudios: vue.editedItem.madre_estudios,
        madre_viveconalumno: vue.editedItem.madre_viveconalumno,

        padre_nombre: vue.editedItem.padre_nombre,
        padre_apellido_paterno: vue.editedItem.padre_apellido_paterno,
        padre_apellido_materno: vue.editedItem.padre_apellido_materno,
        padre_fecha_nacimiento: vue.editedItem.padre_fecha_nacimiento,
        padre_curp: vue.editedItem.padre_curp,
        padre_rfc: vue.editedItem.padre_rfc,
        padre_nacionalidad: vue.editedItem.padre_nacionalidad,

        padre_residencia_calle: vue.editedItem.padre_residencia_calle,
        padre_residencia_numero: vue.editedItem.padre_residencia_numero,
        padre_residencia_interior: vue.editedItem.padre_residencia_interior,
        padre_residencia_colonia: vue.editedItem.padre_residencia_colonia,
        padre_residencia_cp: vue.editedItem.padre_residencia_cp,
        padre_residencia_localidad: vue.editedItem.padre_residencia_localidad,
        padre_residencia_municipio: vue.editedItem.padre_residencia_municipio,
        padre_residencia_entidad: vue.editedItem.padre_residencia_entidad,
        padre_residencia_pais: vue.editedItem.padre_residencia_pais,

        padre_telefono: vue.editedItem.padre_telefono,
        padre_celular: vue.editedItem.padre_celular,
        padre_trabajo_telefono: vue.editedItem.padre_trabajo_telefono,
        padre_trabajo_horario: vue.editedItem.padre_trabajo_horario,
        padre_trabajo_correo: vue.editedItem.padre_trabajo_correo,
        padre_trabajo_domicilio: vue.editedItem.padre_trabajo_domicilio,
        padre_ocupacion: vue.editedItem.padre_ocupacion,
        padre_estudios: vue.editedItem.padre_estudios,
        padre_viveconalumno: vue.editedItem.padre_viveconalumno,

        tutor_nombre: vue.editedItem.tutor_nombre,
        tutor_apellido_paterno: vue.editedItem.tutor_apellido_paterno,
        tutor_apellido_materno: vue.editedItem.tutor_apellido_materno,
        tutor_telefono: vue.editedItem.tutor_telefono,
        tutor_celular: vue.editedItem.tutor_celular,
        tutor_trabajo_telefono: vue.editedItem.tutor_trabajo_telefono,
        tutor_trabajo_horario: vue.editedItem.tutor_trabajo_horario,
        tutor_trabajo_correo: vue.editedItem.tutor_trabajo_correo,
        tutor_trabajo_domicilio: vue.editedItem.tutor_trabajo_domicilio,
        tutor_viveconalumno: vue.editedItem.tutor_viveconalumno,

        familiar1_nombre: vue.editedItem.familiar1_nombre,
        familiar1_parentesco: vue.editedItem.familiar1_parentesco,
        familiar2_nombre: vue.editedItem.familiar2_nombre,
        familiar2_parentesco: vue.editedItem.familiar2_parentesco,
        familiar3_nombre: vue.editedItem.familiar3_nombre,
        familiar3_parentesco: vue.editedItem.familiar3_parentesco,

        salud_numero_embarazo: vue.editedItem.salud_numero_embarazo,
        salud_duracion_embarazo: vue.editedItem.salud_duracion_embarazo,
        salud_complicaciones_embarazo:
          vue.editedItem.salud_complicaciones_embarazo,
        salud_llanto_inmediato: vue.editedItem.salud_llanto_inmediato,
        salud_anoxia: vue.editedItem.salud_anoxia,
        salud_peso_nacer: vue.editedItem.salud_peso_nacer,
        salud_talla: vue.editedItem.salud_talla,
        salud_apgar: vue.editedItem.salud_apgar,
        salud_accidentes: vue.editedItem.salud_accidentes,
        salud_problemas: vue.editedItem.salud_problemas,

        salud_pecho: vue.editedItem.salud_pecho,
        salud_retiro_pecho: vue.editedItem.salud_retiro_pecho,
        salud_alergias: vue.editedItem.salud_alergias,
        salud_alimentacion: vue.editedItem.salud_alimentacion,
        salud_enfermedades: vue.editedItem.salud_enfermedades,
        salud_tratamiento: vue.editedItem.salud_tratamiento,

        escuela_rutina: vue.editedItem.escuela_rutina,
        escuela_procedencia: vue.editedItem.escuela_procedencia,
        escuela_grados: vue.editedItem.escuela_grados,
        escuela_promedio: vue.editedItem.escuela_promedio,
        escuela_motivo: vue.editedItem.escuela_motivo,
        opiniones: vue.editedItem.opiniones,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ActualizaClientes", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.closeedit();
          vue.items = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id: vue.editedItem.id_cliente,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/DesactivaClientes", Params)
        .then(function (response) {
          vue.dialogIni = false;
          vue.closeDelete();
          vue.items = response.data;
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    insus() {
      var a = this.itemsus.find(
        (x) => x.id_suscripcion === this.newItem.id_suscripcion
      );
      this.fechas(a.frecuencia_pago);
    },

    fechas(frec) {
      var a = this.newItem.fecha_inicial;
      let now = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      var newDate = new Date(now.setDate(now.getDate() + 1));
      this.newItem.fecha_final = format(
        parseISO(newDate.toISOString()),
        "yyyy-MM-dd"
      );
      this.fechasfinal(frec);
    },

    fechasfinal(frec) {
      var a = this.newItem.fecha_inicial;
      var b = this.newItem.fecha_final;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      let dat2 = dati;
      if (dati < datf) {
        if (frec == "Anual") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 12));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else if (frec == "Semestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 6));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else if (frec == "Trimestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 3));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else if (frec == "Bimestral") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 2));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else if (frec == "Mensual") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 1));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else if (frec == "Semanal") {
          while (dat2 < datf) {
            dat2 = new Date(dat2.setDate(dat2.getDate() + 7));
          }
          this.newItem.fecha_final = format(
            parseISO(dat2.toISOString()),
            "yyyy-MM-dd"
          );
        } else {
          console.log("error de fechas");
        }
      } else {
        this.fechas(frec);
      }
    },
    fecha1() {
      this.menuDateBuscaIni0 = false;
      this.menuDateBuscaIni = false;
      var a = this.itemsus.find(
        (x) => x.id_suscripcion === this.newItem.id_suscripcion
      );
      this.fechas(a.frecuencia_pago);
    },
    fecha2() {
      this.menuDateBuscaFin0 = false;
      this.menuDateBuscaFin = false;
      var a = this.itemsus.find(
        (x) => x.id_suscripcion === this.newItem.id_suscripcion
      );
      this.fechasfinal(a.frecuencia_pago);
    },
    matriculanew() {
      var vue = this;
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/Matricula", Params)
        .then(function (response) {
          vue.newItem.matricula = response.data.empresa;
          vue.editedItem.matricula = response.data.empresa;
        })
        .catch(function (error) {
          vue.$refs.form7.reset();
          console.log(error);
        });
    },
    excel() {
      this.dialogIni = true;
      var vue = this;
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ImprimirClientes", Params)
        .then(function (response) {
          var rows = response.data;
          var encoding = "data:text/csv;charset=utf-8,";
          var csvContent = "";
          rows.forEach(function (rowArray) {
            var row = rowArray.join(",");
            csvContent += row + "\r\n";
          });
          // console.log(csvContent);
          // var encodedUri = encodeURI(csvContent);
          var encodedUri = escape(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encoding + encodedUri);
          link.setAttribute("download", "Clientes.csv");
          document.body.appendChild(link);
          link.click();
          vue.dialogIni = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    madrecopy() {
      if (this.editedItem.madre_viveconalumno == this.dicotomicas[0]) {
        this.editedItem.madre_residencia_calle = this.editedItem.residencia_calle;
        this.editedItem.madre_residencia_numero =
          this.editedItem.residencia_numero;
        this.editedItem.madre_residencia_interior =
          this.editedItem.residencia_interior;
        this.editedItem.madre_residencia_colonia =
          this.editedItem.residencia_colonia;
        this.editedItem.madre_residencia_cp = this.editedItem.residencia_cp;
        this.editedItem.madre_residencia_localidad =
          this.editedItem.residencia_localidad;
        this.editedItem.madre_residencia_municipio =
          this.editedItem.residencia_municipio;
      }
      else {
        this.editedItem.madre_residencia_calle = "";
        this.editedItem.madre_residencia_numero= "";
        this.editedItem.madre_residencia_interior = "";
        this.editedItem.madre_residencia_colonia = "";
        this.editedItem.madre_residencia_cp = "";
        this.editedItem.madre_residencia_localidad = "";
        this.editedItem.madre_residencia_municipio = "";
      }
    },
    padrecopy() {
      if (this.editedItem.padre_viveconalumno == this.dicotomicas[0]) {
        this.editedItem.padre_residencia_calle = this.editedItem.residencia_calle;
        this.editedItem.padre_residencia_numero =
          this.editedItem.residencia_numero;
        this.editedItem.padre_residencia_interior =
          this.editedItem.residencia_interior;
        this.editedItem.padre_residencia_colonia =
          this.editedItem.residencia_colonia;
        this.editedItem.padre_residencia_cp = this.editedItem.residencia_cp;
        this.editedItem.padre_residencia_localidad =
          this.editedItem.residencia_localidad;
          this.editedItem.padre_residencia_municipio =
          this.editedItem.residencia_municipio;
      } 
      else {
        this.editedItem.padre_residencia_calle = "";
        this.editedItem.padre_residencia_numero= "";
        this.editedItem.padre_residencia_interior = "";
        this.editedItem.padre_residencia_colonia = "";
        this.editedItem.padre_residencia_cp = "";
        this.editedItem.padre_residencia_localidad = "";
        this.editedItem.padre_residencia_municipio = "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.newItem.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.newItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.newItem.fecha_final).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.fecha_inicial).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.fecha_final).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.madre_fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
    if (true) {
      var localLocale = moment(this.editedItem.padre_fecha_nacimiento).format(
        "DD MMM YYYY"
      );
      moment.locale("es");
    }
  },
};
</script>