<template>
  <v-row dense class="fill-height">
    <v-col dense>
      <v-sheet height="40">
        <v-toolbar flat>
          <v-icon>mdi-calendar-check</v-icon>
          <v-divider class="mx-4" vertical></v-divider>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <pre>&nbsp;&nbsp;</pre>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoy
          </v-btn>
          <v-divider class="mx-4" vertical></v-divider>
          <pre>&nbsp;&nbsp;</pre>
          <v-btn class="mr-4" dark color="program" @click="dialog = true">
            Nuevo Evento
          </v-btn>
          <v-dialog v-model="dialog" persistent max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="4">
                      <v-form ref="form1">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Nombre"
                          v-model="nombre"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                    <v-col cols="8">
                      <v-form ref="form2">
                        <v-text-field
                          :rules="[rules.required]"
                          label="Informacion"
                          v-model="details"
                        ></v-text-field>
                      </v-form>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        :rules="[rules.required]"
                        :items="colores"
                        label="Color"
                        v-model="color"
                        :background-color="colors[colores.indexOf(color)]"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="menuDateBuscaI"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormattedMomentjsI"
                            label="Fecha inicial"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="es"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          v-model="dateBuscaI"
                          @change="funfecha1"
                        ></v-date-picker>
                      </v-menu>
                      <br />
                      <v-menu
                        ref="menu2"
                        v-model="menuHI"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="timeI"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        min-height="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="tiempoI"
                            label="Hora inicial"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuHI"
                          v-model="timeI"
                          full-width
                          @click:minute="funt1"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="menuDateBuscaF"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormattedMomentjsF"
                            label="Fecha final"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="es"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          v-model="dateBuscaF"
                          @change="funfecha2"
                        ></v-date-picker>
                      </v-menu>
                      <br />
                      <v-menu
                        ref="menu"
                        v-model="menuHF"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="timeF"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="tiempoF"
                            label="Hora final"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menuHF"
                          v-model="timeF"
                          full-width
                          @click:minute="funt2"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="close"> Cancelar </v-btn>
                <v-btn color="program" text @click="save"> Guardar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogdelete" max-width="400px">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>¿Seguro que deseas eliminar este elemento?</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="program" text @click="eliminar">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Cargando
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialog500" max-width="350">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>Problemas de conexión</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialog500 = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialog404" max-width="350">
            <v-card>
              <v-card-title class="headline"> Error </v-card-title>
              <v-card-text class="text-h6">
                <h5>{{ Message }}</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialog404 = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogA" max-width="400">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>Las fechas no pueden ser mayor a una semana.</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialogA = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogB" max-width="400">
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text class="text-h6">
                <h5>La fecha inicial no debe ser menor que la fecha final.</h5>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="program" text @click="dialogB = false">
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </v-sheet>
      <br />
      <v-sheet height="550">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="EditItem(selectedEvent)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <h4>
                <span v-html="funstart(selectedEvent.start)"></span
                >&nbsp;-&nbsp; <span v-html="funend(selectedEvent.end)"></span>
              </h4>
              <br />
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                :color="selectedEvent.color"
                @click="DelItem(selectedEvent)"
              >
                Eliminar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text :color="selectedEvent.color" @click="closemenu()">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    Message: "Internal Error",
    dateBuscaI: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menuDateBuscaI: false,
    dateBuscaF: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menuDateBuscaF: false,
    timeI: "00:00",
    tiempoI: "12:00 am",
    menuHI: false,
    timeF: "00:00",
    tiempoF: "12:00 am",
    menuHF: false,
    nombre: "",
    details: "",
    color: "",
    id: "",
    diahoy: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    dialog: false,
    dialogdelete: false,
    dialogA: false,
    dialogB: false,
    dialogIni: false,
    dialog404: false,
    dialog500: false,
    rules: {
      required: (value) => !!value || "Requerido",
    },
    focus: "",
    type: "month",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "red",
      "deep-orange",
      "amber darken-1",
      "light-green",
      "indigo",
      "deep-purple",
    ],
    colores: ["Rojo", "Naranja", "Amarillo", "Verde", "Azul", "Púrpura"],
  }),

  computed: {
    formTitle() {
      return this.id == "" ? "Nuevo Evento" : "Editar Evento";
    },
    computedDateFormattedMomentjsI() {
      var localLocale = moment(this.dateBuscaI).format("DD MMM YYYY");
      moment.locale("es");
      return this.dateBuscaI ? localLocale : "";
    },
    computedDateFormattedMomentjsF() {
      var localLocale = moment(this.dateBuscaF).format("DD MMM YYYY");
      moment.locale("es");
      return this.dateBuscaF ? localLocale : "";
    },
  },

  watch: {
    timeI(val) {
      var min = new Date(`2010-01-01T${val}:00`);
      this.tiempoI = moment(min).format("hh:mm a");
    },
    timeF(val) {
      var min = new Date(`2010-01-01T${val}:00`);
      this.tiempoF = moment(min).format("hh:mm a");
    },
    dialog(val) {
      if (val && this.id == "") {
        this.dateBuscaI = this.focus;
        this.dateBuscaF = this.focus;
      }
    },
  },

  methods: {
    funstart(start) {
      var localLocale = moment(start).format("DD MMM YYYY (hh:mm a)");
      moment.locale("es");
      return true ? localLocale : "";
    },
    funend(end) {
      var localLocale = moment(end).format("DD MMM YYYY (hh:mm a)");
      moment.locale("es");
      return true ? localLocale : "";
    },

    initialize() {
      this.type = "month";
      this.focus = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
      this.color = this.colores[0];
      this.cargar();
    },

    closemenu() {
      this.selectedOpen = false;
      this.dialogdelete = false;
      this.dialog = false;
      this.id = "";
      this.$refs.calendar.checkChange();
    },

    close() {
      this.dialog = false;
      this.dateBuscaI = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.dateBuscaF = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.timeI = "00:00";
      this.timeF = "00:00";
      this.tiempoI = moment(new Date(`2010-01-01T00:00:00`)).format("hh:mm a");
      this.tiempoF = moment(new Date(`2010-01-01T00:00:00`)).format("hh:mm a");
      this.color = this.colores[0];
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.calendar.checkChange();
    },

    closeEdit() {
      this.selectedOpen = false;
      this.dialogdelete = false;
      this.dialog = false;
      this.id = "";
      this.dateBuscaI = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.dateBuscaF = format(
        parseISO(new Date().toISOString()),
        "yyyy-MM-dd"
      );
      this.timeI = "00:00";
      this.timeF = "00:00";
      this.tiempoI = moment(new Date(`2010-01-01T00:00:00`)).format("hh:mm a");
      this.tiempoF = moment(new Date(`2010-01-01T00:00:00`)).format("hh:mm a");
      this.color = this.colores[0];
      this.$refs.form1.reset();
      this.$refs.form2.reset();
      this.$refs.calendar.checkChange();
    },

    closeDelete() {
      this.selectedOpen = false;
      this.dialogdelete = false;
      this.dialog = false;
      this.id = "";
      this.$refs.calendar.checkChange();
    },

    save() {
      var a = this.$refs.form1.validate();
      var b = this.$refs.form2.validate();
      if (a && b) {
        if (this.id == "") {
          this.agregar();
        } else {
          this.editar();
        }
      }
    },

    DelItem(item) {
      this.id = item.id_actividad;
      this.dialogdelete = true;
    },

    EditItem(item) {
      this.id = item.id_actividad;
      this.nombre = item.name;
      this.details = item.details;
      this.color = this.colores[this.colors.indexOf(item.color)];
      this.dateBuscaI = format(
        parseISO(item.start.toISOString()),
        "yyyy-MM-dd"
      );
      this.timeI = format(parseISO(item.start.toISOString()), "HH:mm");
      this.dateBuscaF = format(parseISO(item.end.toISOString()), "yyyy-MM-dd");
      this.timeF = format(parseISO(item.end.toISOString()), "HH:mm");
      this.dialog = true;
    },

    setToday() {
      console.log({});
      if (
        this.focus.substring(0, 7) ==
        format(parseISO(new Date().toISOString()), "yyyy-MM")
      ) {
        if (this.type == "month") {
          this.type = "day";
          this.focus = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        } else {
          this.type = "month";
          this.focus = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        }
      } else {
        this.type = "month";
        this.focus = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
      }
      this.cargar();
    },
    prev() {
      this.$refs.calendar.prev();
      this.cargar();
    },
    next() {
      this.$refs.calendar.next();
      this.cargar();
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargar() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
        fecha: vue.focus,
      };
      axios
        .post("api/SistemaCobros/ConsultaAct", Params)
        .then(function (response) {
          vue.events = response.data;
          for (var i = 0; i < response.data.length; i++) {
            vue.events[i].start = new Date(`${response.data[i].start}`);
            vue.events[i].end = new Date(`${response.data[i].end}`);
          }
          vue.dialogIni = false;
          vue.$refs.calendar.checkChange();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.events = [];
          vue.$refs.calendar.checkChange();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              if (vue.Message != "No se han encontrado datos.") {
                vue.dialog404 = true;
              }
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        name: vue.nombre,
        details: vue.details,
        start: `${this.dateBuscaI}T${this.timeI}:00`,
        end: `${this.dateBuscaF}T${this.timeF}:00`,
        color: vue.colors[vue.colores.indexOf(vue.color)],
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
        fecha: vue.focus,
      };
      vue.selectedEvent = Params;
      axios
        .post("api/SistemaCobros/InsertaAct", Params)
        .then(function (response) {
          vue.events = response.data;
          for (var i = 0; i < response.data.length; i++) {
            vue.events[i].start = new Date(`${response.data[i].start}`);
            vue.events[i].end = new Date(`${response.data[i].end}`);
          }
          vue.dialogIni = false;
          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.events = [];
          vue.$refs.calendar.checkChange();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              if (vue.Message != "No se han encontrado datos.") {
                vue.dialog404 = true;
              }
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.close();
        });
    },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_actividad: vue.id,
        name: vue.nombre,
        details: vue.details,
        start: `${this.dateBuscaI}T${this.timeI}:00`,
        end: `${this.dateBuscaF}T${this.timeF}:00`,
        color: vue.colors[vue.colores.indexOf(vue.color)],
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
        fecha: vue.focus,
      };
      vue.selectedEvent = Params;
      axios
        .post("api/SistemaCobros/ActualizaAct", Params)
        .then(function (response) {
          vue.events = response.data;
          for (var i = 0; i < response.data.length; i++) {
            vue.events[i].start = new Date(`${response.data[i].start}`);
            vue.events[i].end = new Date(`${response.data[i].end}`);
          }
          vue.dialogIni = false;
          vue.closeEdit();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.events = [];
          vue.$refs.calendar.checkChange();
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              if (vue.Message != "No se han encontrado datos.") {
                vue.dialog404 = true;
              }
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.closeEdit();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_actividad: vue.id,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
        fecha: vue.focus,
      };
      axios
        .post("api/SistemaCobros/DesactivaAct", Params)
        .then(function (response) {
          vue.events = response.data;
          for (var i = 0; i < response.data.length; i++) {
            vue.events[i].start = new Date(`${response.data[i].start}`);
            vue.events[i].end = new Date(`${response.data[i].end}`);
          }
          vue.dialogIni = false;
          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          vue.events = [];
          vue.$refs.calendar.checkChange();
          if (error.response) {
            vue.events = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              if (vue.Message != "No se han encontrado datos.") {
                vue.dialog404 = true;
              }
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.closeDelete();
        });
    },

    viewDay({ date }) {
      if (this.type == "month") {
        this.type = "day";
        this.focus = date;
      } else {
        this.type = "month";
        this.focus = date;
      }
    },
    getEventColor(event) {
      return event.color;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    funfecha1() {
      this.menuDateBuscaI = false;
      var a = this.dateBuscaI;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );

      var newDate = new Date(dati.setDate(dati.getDate()));
      this.dateBuscaF = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");

      this.timeF = this.timeI;
      this.$refs.menu.save(this.timeF);
    },

    funfecha2() {
      this.menuDateBuscaF = false;

      var a = this.dateBuscaI;
      var b = this.dateBuscaF;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );

      if (dati > datf) {
        //this.dialogB = true;
        var newDate = new Date(dati.setDate(dati.getDate()));
        this.dateBuscaF = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }
      var siete = new Date(dati.setDate(dati.getDate() + 7));
      if (siete < datf) {
        //this.dialogA = true;
        var newDate = new Date(siete.setDate(siete.getDate()));
        this.dateBuscaF = format(parseISO(newDate.toISOString()), "yyyy-MM-dd");
      }

      this.timeF = this.timeI;
      this.$refs.menu.save(this.timeF);
    },

    funt1() {
      this.$refs.menu2.save(this.timeI);
      this.timeF = this.timeI;
      this.$refs.menu.save(this.timeF);
    },
    funt2() {
      var a = this.dateBuscaI;
      var b = this.dateBuscaF;
      let dati = new Date(
        a.substr(0, 4),
        (parseInt(a.substr(5, 2)) - 1).toString(),
        a.substr(8, 2)
      );
      let datf = new Date(
        b.substr(0, 4),
        (parseInt(b.substr(5, 2)) - 1).toString(),
        b.substr(8, 2)
      );
      if (dati.toString() == datf.toString()) {
        if (
          new Date(`${this.dateBuscaI}T${this.timeI}:00`) >
          new Date(`${this.dateBuscaI}T${this.timeF}:00`)
        ) {
          this.timeF = this.timeI;
        }
      }
      this.$refs.menu.save(this.timeF);
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.$refs.calendar.checkChange();
    this.initialize();
  },
  created() {
    if (true) {
      var localLocaleI = moment(this.dateBuscaI).format("DD MMM YYYY");
      moment.locale("es");
    }
    if (true) {
      var localLocaleF = moment(this.dateBuscaF).format("DD MMM YYYY");
      moment.locale("es");
    }
  },
};
</script>