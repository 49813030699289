<template>
  <v-row
    ><v-col>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        disable-sort
        :item-class="itemRowBackground"
        sort-by="id_gasto"
        class="elevation-1"
        :footer-props="{ itemsPerPageText: 'Filas por página:' }"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-icon>mdi-scale-balance</v-icon>
            <v-divider class="mx-4" vertical></v-divider>
            <v-menu
              v-model="menuDateBuscaA"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjsA"
                  label="Fecha de Busqueda: "
                  readonly
                  v-bind="attrs"
                  hide-details
                  v-on="on"
                  locale="es"
                  class="tam"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha1"
                @change="funfecha1"
                type="month"
              ></v-date-picker>
            </v-menu>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon dense large text @click="excel">
              <v-icon title="Exportar Tabla">mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-divider class="mx-4" vertical></v-divider>
            <v-dialog v-model="dialog" persistent max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="program"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nuevo
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="4">
                        <v-form ref="formopt1">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.concepto"
                            label="Concepto"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="formopt2">
                          <v-text-field
                            :rules="[rules.required, rules.importe]"
                            v-model="editedItem.importe"
                            label="Importe"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                      <v-col cols="4">
                        <v-form ref="formopt3">
                          <v-select
                            :rules="[rules.required]"
                            :items="tipos"
                            v-model="editedItem.actual"
                            label="Tipo"
                            :background-color="
                              tips[tipos.indexOf(editedItem.actual)]
                            "
                          ></v-select>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="close"> Cancelar </v-btn>
                  <v-btn color="program" text @click="save"> Guardar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-h6">
                  <h5>¿Seguro que deseas eliminar este elemento?</h5>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="program" text @click="closeDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="program" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <template v-if="item.actual == 'Ingreso'">
            ${{ item.importe }}
          </template>
          <template v-if="item.actual == 'Egreso'">
            -&nbsp;${{ item.importe }}
          </template>
        </template>
        <template v-slot:[`item.id_gasto`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Editar" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon title="Eliminar" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <br />
          <p>Sin datos disponibles</p>
        </template>
      </v-data-table>
      <br />
      <template>
        <v-toolbar flat>
          <v-spacer />
          <v-icon>mdi-cash-multiple</v-icon>
          <pre>&nbsp;&nbsp;</pre>
          <v-toolbar-title>Total</v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-toolbar-title> {{ total_num }} </v-toolbar-title>
        </v-toolbar>
      </template>
      <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog500" max-width="350">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>Problemas de conexión</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog500 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog404" max-width="350">
        <v-card>
          <v-card-title class="headline"> Error </v-card-title>
          <v-card-text class="text-h6">
            <h5>{{ Message }}</h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialog404 = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogA" max-width="400">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text class="text-h6">
            <h5>
              Solo se pueden buscar los gastos del dia de hoy o de fechas
              anteriores.
            </h5>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="program" text @click="dialogA = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<style>
.style-4 {
  color: rgb(132, 0, 0);
  background-color: rgb(255, 255, 255);
}
.style-44 {
  color: rgb(0, 132, 0);
  background-color: rgb(255, 255, 255);
}
</style>

<script>
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
      importe: (value) => {
        const pattern =
          /^([1-9][0-9]*\.[0-9][0-9]|[0]\.[0-9][1-9]|[0]\.[1-9][0-9])$/;
        return pattern.test(value) || "Importe inválido, ejemplo: 0.01";
      },
    },
    total_num: "0.00",
    fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    menuDateBuscaA: false,
    Message: "Internal Error",
    dialog: false,
    dialogA: false,
    dialogDelete: false,
    dialogIni: false,
    dialog500: false,
    dialog404: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_gasto",
      },
      { text: "Concepto", value: "concepto", align: "center" },
      { text: "Importe", value: "importe", align: "center" },
      { text: "Tipo", value: "actual", align: "center" },
      { text: "Fecha", value: "datos", align: "center" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    items: [],
    tipos: ["Egreso", "Ingreso"],
    tips: ["#FF0000D0", "#00FF00B0"],
    editedIndex: -1,
    id_objeto: "",
    editedItem: {
      id_gasto: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      concepto: "",
      importe: "",
      actual: "Egreso",
      datos: "",
    },
    defaultItem: {
      id_gasto: "",
      fecha_inicial: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha_final: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      concepto: "",
      importe: "",
      actual: "Egreso",
      datos: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
    computedDateFormattedMomentjsA() {
      var localLocale = moment(this.fecha1).format("MMMM YYYY");
      moment.locale("es");
      return this.fecha1 ? localLocale : "";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    itemRowBackground: function (item) {
      if (item.actual == "Egreso") {
        return "style-4";
      } else {
        return "style-44";
      }
    },

    initialize() {
      this.items = [];
      this.cargartodo();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_gasto;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.id_objeto = item.id_gasto;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //this.items.splice(this.editedIndex, 1);
      this.eliminar();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        this.$refs.formopt1.reset();
        this.$refs.formopt2.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.id_objeto = "";
        this.$refs.formopt1.reset();
        this.$refs.formopt2.reset();
      });
    },

    save() {
      var a = this.$refs.formopt1.validate();
      var b = this.$refs.formopt2.validate();
      var c = this.$refs.formopt3.validate();
      if (a && b && c) {
        if (this.editedIndex > -1) {
          //Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editar();
        } else {
          //this.items.push(this.editedItem);
          this.agregar();
        }
      }
    },

    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    cargartodo() {
      var vue = this;
      vue.dialogIni = true;
      if (vue.$store.state.empresa == null || vue.$store.state.empresa == "") {
        vue.reloadPage();
        return;
      }
      var Params = {
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
        fecha: vue.fecha1,
      };
      axios
        .post("api/SistemaCobros/ConsultaGastos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;

          var suma = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].actual == "Egreso") {
              suma = suma - parseFloat(response.data[i].importe);
            } else {
              suma = suma + parseFloat(response.data[i].importe);
            }
          }
          if (suma >= 0) {
            vue.total_num = "$" + suma.toFixed(2);
          } else {
            vue.total_num = "- $" + (suma * -1).toFixed(2);
          }
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
        });
    },

    agregar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_gasto: vue.id_objeto,
        concepto: vue.editedItem.concepto,
        importe: vue.editedItem.importe,
        actual: vue.editedItem.actual,
        fecha_inicial: vue.fecha1,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/InsertaGastos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;

          var suma = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].actual == "Egreso") {
              suma = suma - parseFloat(response.data[i].importe);
            } else {
              suma = suma + parseFloat(response.data[i].importe);
            }
          }
          if (suma >= 0) {
            vue.total_num = "$" + suma.toFixed(2);
          } else {
            vue.total_num = "- $" + (suma * -1).toFixed(2);
          }

          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.close();
        });
    },

    editar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_gasto: vue.id_objeto,
        concepto: vue.editedItem.concepto,
        importe: vue.editedItem.importe,
        actual: vue.editedItem.actual,
        fecha_inicial: vue.fecha1,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/ActualizaGastos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;

          var suma = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].actual == "Egreso") {
              suma = suma - parseFloat(response.data[i].importe);
            } else {
              suma = suma + parseFloat(response.data[i].importe);
            }
          }
          if (suma >= 0) {
            vue.total_num = "$" + suma.toFixed(2);
          } else {
            vue.total_num = "- $" + (suma * -1).toFixed(2);
          }

          vue.close();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.close();
        });
    },

    eliminar() {
      var vue = this;
      vue.dialogIni = true;
      var Params = {
        id_gasto: vue.id_objeto,
        fecha_inicial: vue.fecha1,
        empresa: vue.$store.state.empresa,
        u: vue.$store.state.usuario,
      };
      axios
        .post("api/SistemaCobros/DesactivaGastos", Params)
        .then(function (response) {
          vue.items = response.data;
          vue.id_objeto = "";
          vue.dialogIni = false;

          var suma = 0.0;
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].actual == "Egreso") {
              suma = suma - parseFloat(response.data[i].importe);
            } else {
              suma = suma + parseFloat(response.data[i].importe);
            }
          }
          if (suma >= 0) {
            vue.total_num = "$" + suma.toFixed(2);
          } else {
            vue.total_num = "- $" + (suma * -1).toFixed(2);
          }

          vue.closeDelete();
        })
        .catch(function (error) {
          vue.dialogIni = false;
          if (error.response) {
            vue.items = [];
            if (error.response.status == 400) {
              vue.Message = error.response.data;
              vue.dialog404 = true;
            } else {
              vue.dialog500 = true;
            }
          } else {
            vue.dialog500 = true;
          }
          console.log(error);
          vue.id_objeto = "";
          vue.closeDelete();
        });
    },
    funfecha1() {
      this.menuDateBuscaA = false;

      // var a = this.fecha1;
      // let dati = new Date(
      //   a.substr(0, 4),
      //   (parseInt(a.substr(5, 2)) - 1).toString(),
      //   a.substr(8, 2)
      // );
      // let hoy = new Date();
      // if (hoy < dati) {
      //   this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
      //   this.dialogA = true;
      // }

      this.cargartodo();
    },

    excel() {
      this.dialogIni = true;
      var rows = [];
      rows.push([
        this.headers[0].text,
        this.headers[1].text,
        this.headers[2].text,
        this.headers[3].text,
        this.headers[4].text,
      ]);
      for (var i = 0; i < this.items.length; i++) {
        rows.push([
          this.items[i].id_gasto,
          this.items[i].concepto,
          this.items[i].importe,
          this.items[i].actual,
          this.items[i].datos.substr(0, 2) +
            "/" +
            this.items[i].datos.substr(3, 2) +
            "/" +
            this.items[i].datos.substr(6, 4),
        ]);
      }
      var encoding = "data:text/csv;charset=utf-8,";
      var csvContent = "";
      rows.forEach(function (rowArray) {
        var row = rowArray.join(",");
        csvContent += row + "\r\n";
      });
      // console.log(csvContent);
      // var encodedUri = encodeURI(csvContent);
      var encodedUri = escape(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encoding + encodedUri);
      link.setAttribute("download", "Ingresos_Egresos.csv");
      document.body.appendChild(link);
      link.click();
      this.dialogIni = false;
    },
  },
  mounted() {
    this.$store.dispatch("autologin");
    this.initialize();
  },
  created() {
    if (true) {
      var localLocale = moment(this.fecha1).format(" MMMM YYYY");
      moment.locale("es");
    }
  },
};
</script>